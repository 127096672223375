export const settingsSlideDesktop = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    centerPadding: "22%",
    centerMode: true,
    responsive: [
        {
            breakpoint: 992,
            settings: {
                centerPadding: "0",
                arrows: false
            }
        }
    ]
};
export const settingsSlideMobile = {
  dots: false,
  infinite: true,
  speed: 500,
  slidesToShow: 3,
  arrows: false,
  centerPadding: "0",
  initialSlide: 0,
}