import React, { useEffect, useRef, useState } from 'react'
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import Slider from "react-slick";

import './index.scss';

import { PosterVideo1, PosterVideo2, PosterVideo3, PosterVideo4, PosterVideo5, PosterVideo6 } from '@components/reuseable/image';
import { Video1, Video2, Video3, Video4, Video5, Video6 } from '@components/reuseable/image';
import { ScoreService } from '@services/score';
import { ISaveScore } from '@interfaces/score';
import { settingsSlideDesktop, settingsSlideMobile } from '@utils/slides';
import { Cookie } from '@utils/cookie';
import { TrackingUtils } from '@utils/gtag';
import { trackVars } from '@utils/trackingVariables';

interface ITheatreSlide {
    title: any,
    showPoints: any,
    remind: any
}

const SlideSlick = (props: ITheatreSlide) => {
    const [nav1, setNav1] = useState();
    const [nav2, setNav2] = useState();
    const [isOpenPoints, setIsOpenPoints] = useState(false);
    const handleChange = (e: any) => {
    let items = document.querySelectorAll(".theatre-slide-item");
    let h3 = document.querySelector(".theatre-title h3");
    let videos = document.querySelectorAll('video');
    items.forEach((item: any) => {
        if (item.getAttribute("data-index") == e) {
            videos.forEach((video: HTMLMediaElement) => {
                video.pause();
            })
            let title = item.getAttribute("data-title");
            props.title(title);
            h3?.classList.add("active");
        }
    });
    };
    const beforeChange = () => {
        let h3 = document.querySelector(".theatre-title h3");
        h3?.classList.remove("active");
    }
    const SaveScrores = async (id: string) => {
        const data: ISaveScore = {
            score: 50,
            activity: "VIEW_VIDEO",
            target: id
        }
        try {
            const res = await ScoreService.SaveScore(data);
            if (res) {
                setIsOpenPoints(true);
                props.showPoints(isOpenPoints);
            }
        }
        catch(err) {
            console.log(err)
        }
        finally {

        }
    }
    const videoTimeUpdate = (event: any) => {
        const name = event.target.getAttribute('data-name');
        let totalTime: number = event.target.duration;
        let watchedTime: number = event.target.currentTime * 100;
        let data_25: string = event.target.getAttribute("data-25");
        let data_50: string = event.target.getAttribute("data-50");
        let data_75: string = event.target.getAttribute("data-75");
        let data_100: string = event.target.getAttribute("data-100");
        if (Math.floor(watchedTime / totalTime) >= 25 && data_25 == "false") {
            TrackingUtils.SendGA(trackVars.eventName, trackVars.video.category, `${trackVars.theatre.lable}_${name}`, `${trackVars.video.action.videoplayed} 25%`)
            event.target.setAttribute("data-25", "true");
            // console.log('25%');
        }
        if (Math.floor(watchedTime / totalTime) >= 50 && data_50 == "false") {
            TrackingUtils.SendGA(trackVars.eventName, trackVars.video.category, `${trackVars.theatre.lable}_${name}`, `${trackVars.video.action.videoplayed} 50%`)
            event.target.setAttribute("data-50", "true");
            // console.log('50%')
        }
        if (Math.floor(watchedTime / totalTime) >= 75 && data_75 == "false") {
            TrackingUtils.SendGA(trackVars.eventName, trackVars.video.category, `${trackVars.theatre.lable}_${name}`, `${trackVars.video.action.videoplayed} 75%`)
            event.target.setAttribute("data-75", "true");
            // console.log('75%')
        }
        if (Math.floor(watchedTime / totalTime) >= 100 && data_100 == "false") {
            TrackingUtils.SendGA(trackVars.eventName, trackVars.video.category, `${trackVars.theatre.lable}_${name}`, `${trackVars.video.action.videoplayed} 100%`)
            event.target.setAttribute("data-100", "true");
            // console.log('100%')
        }
    }
    const onPlayVideo = (e: any) => {
        const name = e.target.getAttribute('data-name');
        TrackingUtils.SendGA(trackVars.eventName, trackVars.video.category, `Theatre_${name}`, trackVars.video.action.clickPlay)
    }
    const onPauseVideo = (e: any) => {
        const fullName = e.target.getAttribute('data-fullName')
        if (e.target.duration > e.target.currentTime) {
            TrackingUtils.SendGA(trackVars.eventName, trackVars.category, trackVars.theatre.lable, `${fullName} ${trackVars.video.action.paused}`)
        }
    }
    const onEndVideo = (e: any) => {
        if (!Cookie.Get("user_token")) {
            props.remind();
            Cookie.Set("temp_theatre", e.target.getAttribute('data-id'));
        } else {
            SaveScrores(e.target.getAttribute('data-id'))
        }
    }
    const onVolumnChange = (e: any) => {
        const fullName = e.target.getAttribute('data-fullName');
        const muted = e.nativeEvent.target.muted;
        if (muted) {
            TrackingUtils.SendGA(trackVars.eventName, trackVars.category, trackVars.theatre.lable, `${fullName} ${trackVars.video.action.soundOff}`);
        } else {
            TrackingUtils.SendGA(trackVars.eventName, trackVars.category, trackVars.theatre.lable, `${fullName} ${trackVars.video.action.soundOn}`);
        }
    }
    return (
        <div className="theatre-slide">
            <Slider {...settingsSlideDesktop} afterChange={handleChange} asNavFor={nav2} ref={(slider1: any) => setNav1(slider1)} beforeChange={beforeChange}>
                <div className="theatre-slide-item" data-title="Welcome To Monster High" data-index={0}>
                    <div className="theatre-slide-item-container">
                        <div className="theatre-slide-item-container-border">
                            <video 
                                onTimeUpdate={videoTimeUpdate} 
                                data-25="false" data-50="false" data-75="false" data-100="false" 
                                onPlay={(e: any) => onPlayVideo(e)}
                                onPause={(e: any) => onPauseVideo(e)}
                                onEnded={(e: any) => onEndVideo(e)} 
                                onVolumeChange={(e) => onVolumnChange(e)}
                                className="theatre-video" 
                                data-id="1" 
                                data-name="Welcome15s Video"
                                data-fullName="Welcome 15 Sec Video"
                                poster={PosterVideo1} controls
                            >
                                <source src={Video1} type='video/mp4' />
                            </video>
                        </div>
                    </div>
                </div>
                <div className="theatre-slide-item" data-title="Draculaura" data-index={1}>
                    <div className="theatre-slide-item-container">
                        <div className="theatre-slide-item-container-border">
                            <video 
                                onTimeUpdate={videoTimeUpdate} 
                                data-25="false" data-50="false" data-75="false" data-100="false" 
                                onPlay={(e: any) => onPlayVideo(e)}
                                onPause={(e: any) => onPauseVideo(e)}
                                onEnded={(e: any) => onEndVideo(e)} 
                                onVolumeChange={(e) => onVolumnChange(e)}
                                className="theatre-video" data-id="2" 
                                data-name="Draculaura Video"
                                data-fullName="Draculaura Video"
                                poster={PosterVideo2} data-index={1} controls
                            >
                                <source src={Video2} type='video/mp4' />
                            </video>
                        </div>
                    </div>
                </div>
                <div className="theatre-slide-item" data-title="Clawdeen Wolf" data-index={2}>
                    <div className="theatre-slide-item-container">
                        <div className="theatre-slide-item-container-border">
                            <video 
                                onTimeUpdate={videoTimeUpdate} 
                                data-25="false" data-50="false" data-75="false" data-100="false" 
                                onPlay={(e: any) => onPlayVideo(e)}
                                onPause={(e: any) => onPauseVideo(e)}
                                onEnded={(e: any) => onEndVideo(e)} 
                                onVolumeChange={(e) => onVolumnChange(e)}
                                className="theatre-video" data-id="3" 
                                data-name="ClawdeenWolf Video"
                                data-fullName="ClawdeenWolf Video"
                                poster={PosterVideo3} controls
                            >
                                <source src={Video3} type='video/mp4' />
                            </video>
                        </div>
                    </div>
                </div>
                <div className="theatre-slide-item" data-title="Frankie Stein" data-index={3}>
                    <div className="theatre-slide-item-container">
                        <div className="theatre-slide-item-container-border">    
                            <video 
                                onTimeUpdate={videoTimeUpdate} 
                                data-25="false" data-50="false" data-75="false" data-100="false" 
                                onPlay={(e: any) => onPlayVideo(e)}
                                onPause={(e: any) => onPauseVideo(e)}
                                onEnded={(e: any) => onEndVideo(e)} 
                                onVolumeChange={(e) => onVolumnChange(e)}
                                className="theatre-video" data-id="4" 
                                data-name="FrankieStein Video"
                                data-fullName="FrankieStein Video"
                                poster={PosterVideo4} controls
                            >
                                <source src={Video4} type='video/mp4' />
                            </video>
                        </div>
                    </div>
                </div>
                <div className="theatre-slide-item" data-title="Lagoona Blue" data-index={4}>
                    <div className="theatre-slide-item-container">
                        <div className="theatre-slide-item-container-border">
                            <video 
                                onTimeUpdate={videoTimeUpdate} 
                                data-25="false" data-50="false" data-75="false" data-100="false" 
                                onPlay={(e: any) => onPlayVideo(e)}
                                onPause={(e: any) => onPauseVideo(e)}
                                onEnded={(e: any) => onEndVideo(e)} 
                                onVolumeChange={(e) => onVolumnChange(e)}
                                className="theatre-video" data-id="5" 
                                data-name="LagoonaBlue Video"
                                data-fullName="LagoonaBlue Video"
                                poster={PosterVideo5} controls
                            >
                                <source src={Video5} type='video/mp4' />
                            </video>
                        </div>
                    </div>
                </div>
                <div className="theatre-slide-item" data-title="Cleo de Nile" data-index={5}>
                    <div className="theatre-slide-item-container">
                        <div className="theatre-slide-item-container-border">
                            <video 
                                onTimeUpdate={videoTimeUpdate} 
                                data-25="false" data-50="false" data-75="false" data-100="false" 
                                onPlay={(e: any) => onPlayVideo(e)}
                                onPause={(e: any) => onPauseVideo(e)}
                                onEnded={(e: any) => onEndVideo(e)} 
                                onVolumeChange={(e) => onVolumnChange(e)}
                                className="theatre-video" data-id="6" 
                                data-name="CleodeNile Video"
                                data-fullName="CleodeNile Video"
                                poster={PosterVideo6} controls
                            >
                                <source src={Video6} type='video/mp4' />
                            </video>
                        </div>
                    </div>
                </div>
            </Slider>
            <div className="theatre-mslide">
            <Slider 
                {...settingsSlideMobile} 
                asNavFor={nav1}
                ref={(slider2: any) => setNav2(slider2)} swipeToSlide={true}
                focusOnSelect={true} 
                afterChange={handleChange} 
                beforeChange={beforeChange}
            >
                <div className="theatre-mslide-item">
                    <div className="theatre-mslide-item-image">
                        <img src={PosterVideo1} alt="" />
                    </div>
                    <div className="theatre-mslide-item-title">
                        <p className="g-book">Welcome To Monster High</p>
                    </div>
                </div>
                <div className="theatre-mslide-item">
                    <div className="theatre-mslide-item-image">
                        <img src={PosterVideo2} alt="" />
                    </div>
                    <div className="theatre-mslide-item-title">
                        <p className="g-book">Draculaura</p>
                    </div>
                </div>
                <div className="theatre-mslide-item">
                    <div className="theatre-mslide-item-image">
                        <img src={PosterVideo3} alt="" />
                    </div>
                    <div className="theatre-mslide-item-title">
                        <p className="g-book">Clawdeen Wolf</p>
                    </div>
                </div>
                <div className="theatre-mslide-item">
                    <div className="theatre-mslide-item-image">
                        <img src={PosterVideo4} alt="" />
                    </div>
                    <div className="theatre-mslide-item-title">
                        <p className="g-book">Frankie Stein</p>
                    </div>
                </div>
                <div className="theatre-mslide-item">
                    <div className="theatre-mslide-item-image">
                        <img src={PosterVideo5} alt="" />
                    </div>
                    <div className="theatre-mslide-item-title">
                        <p className="g-book">Lagoona Blue</p>
                    </div>
                </div>
                <div className="theatre-mslide-item">
                    <div className="theatre-mslide-item-image">
                        <img src={PosterVideo6} alt="" />
                    </div>
                    <div className="theatre-mslide-item-title">
                        <p className="g-book">Cleo de Nile</p>
                    </div>
                </div>
            </Slider>
            </div>
        </div>
    );
}

export default SlideSlick