import React from 'react'
import './index.scss'
import LoginBtn from "@assets/images/login-btn-2.png";
import StayBtn from "@assets/images/stay-btn.png";
import CloseHandbook from "@assets/images/close-handbook.png";
import { useNavigate } from 'react-router';

interface IPoints {
    close: any,
    isShowing: boolean,
}

const LoginRemind = (props: IPoints) => {
    const navigate = useNavigate();
  return (
    <div className={`login-remind ${props.isShowing ? "active" : ""}`}>
        <div className="login-remind-box">
            <p className="tb">Login to keep points and become the top student to win amazing Monster High prizes!</p>
            <img className='btn' onClick={() => navigate("/login")} src={LoginBtn} alt="" />
            <img className='btn' onClick={props.close} src={StayBtn} alt="" />
            {/* <img onClick={props.close} className='close' src={CloseHandbook} alt="" /> */}
        </div>
    </div>
  )
}

export default LoginRemind