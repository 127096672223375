export const Shape_1 = require("@assets/images/shape-one.png");
export const Shape_2 = require("@assets/images/shape-two.png");
export const Shape_3 = require("@assets/images/shape-three.png");
export const Avatar_1 = require("@assets/images/avatar/1.png");
export const Avatar_2 = require("@assets/images/avatar/2.png");
export const Avatar_3 = require("@assets/images/avatar/3.png");
export const Avatar_4 = require("@assets/images/avatar/4.png");
export const Avatar_5 = require("@assets/images/avatar/5.png");
export const Color_1 = require("@assets/images/color-1.png");
export const Color_2 = require("@assets/images/color-2.png");
export const Color_3 = require("@assets/images/color-3.png");
export const Color_4 = require("@assets/images/color-4.png");
export const Color_5 = require("@assets/images/color-5.png");

export const SubmitBtn = require("@assets/images/complete-btn.png");

export const PosterVideo1 = require("@assets/images/thumbnail-1-All.jpg");
export const PosterVideo2 = require("@assets/images/thumbnail-2-Draculaura.jpg");
export const PosterVideo3 = require("@assets/images/thumbnail-3-Clawdeen.jpg");
export const PosterVideo4 = require("@assets/images/thumbnail-4-Frankie.jpg");
export const PosterVideo5 = require("@assets/images/thumbnail-5-Lagoona.jpg");
export const PosterVideo6 = require("@assets/images/thumbnail-6-Cleo.jpg");

export const Video1 = require("@assets/videos/WelcomeToMonsterHigh.mp4");
export const Video2 = require("@assets/videos/Draculaura.mp4");
export const Video3 = require("@assets/videos/ClawdeenWolf.mp4");
export const Video4 = require("@assets/videos/FrankieStein.mp4");
export const Video5 = require("@assets/videos/LagoonaBlue.mp4");
export const Video6 = require("@assets/videos/CleoDeNile.mp4");

export const VideoIntroHome = require("@assets/videos/AU_MonsterHigh_IntroVideo_v2.mp4");

export const HomeBtnLabo = require("@assets/images/home-btn-labo.png");
export const HomeBtnTheatre = require("@assets/images/home-btn-theatre.png");
export const HomeBtnLocker = require("@assets/images/home-btn-lockers.png");
export const HomeBtnCreate = require("@assets/images/home-btn-create.png");
export const HomeBtnHandbook = require("@assets/images/home-btn-handbook.png");
export const HomeDolls = require("@assets/images/home-dolls.png");
export const HomeGuideCharacter = require("@assets/images/home-guide-character.png");
export const HomeGuideName = require("@assets/images/home-guide-name.png");

export const TodoLaborQuizz = require("@assets/images/todo/labo_quiz.png");
export const TodoLaborTrivia = require("@assets/images/todo/labo_trivia.png");
export const TodoLaborMatch = require("@assets/images/todo/labo_match.png");
export const TodoTheatre = require("@assets/images/todo/theatre.png");
export const TodoLockers = require("@assets/images/todo/lockers.png");
export const TodoCreate = require("@assets/images/todo/create.png");

export const LockersClawdeen = require("@assets/images/locker-clawdeen.png");
export const LockersCleo = require("@assets/images/locker-cleo.png");
export const LockersDraculaura = require("@assets/images/locker-draculaura.png");
export const LockersFrankie = require("@assets/images/locker-frankie.png");
export const LockersLagoon = require("@assets/images/locker-lagoona.png");

export const LockersCircleClawdeen = require("@assets/images/locker-circle-clawdeen.png");
export const LockersCircleCleo = require("@assets/images/locker-circle-cleo.png");
export const LockersCircleDraculaura = require("@assets/images/locker-circle-draculaura.png");
export const LockersCircleFrankie = require("@assets/images/locker-circle-frankie.png");
export const LockersCircleLagoon = require("@assets/images/locker-circle-lagoona.png");

export const QuizGame = require("@assets/images/game-1-btn.png");
export const TriviaGame = require("@assets/images/game-2-btn.png");
export const MatchGame = require("@assets/images/game-3-btn-2.png");
export const MQuizGame = require("@assets/images/m-game-1-btn.png");
export const MTriviaGame = require("@assets/images/m-game-2-btn.png");
export const MMatchGame = require("@assets/images/m-game-3-btn-2.png");