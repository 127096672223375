import React, { useEffect, useState } from 'react'
import './index.scss';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import Slider from "react-slick";

import { TodoCreate, TodoLaborMatch, TodoLaborTrivia, TodoLaborQuizz, TodoLockers, TodoTheatre } from '@components/reuseable/image';
import { IProfile } from '@interfaces/auth';
import { ISaveScore } from '@interfaces/score';
import { useNavigate } from 'react-router';
import { TrackingUtils } from '@utils/gtag';
import { trackVars } from '@utils/trackingVariables';

interface IProfileBox {
    data: IProfile
}

const ProfileBox = (props: IProfileBox) => {
    const navigate = useNavigate();
    const [isVideoAct, setIsVideoAct] = useState<boolean>(false);
    const [isLockersAct, setIsLockersAct] = useState<boolean>(false);
    const [isQuizzAct, setIsQuizzAct] = useState<boolean>(false);
    const [isTriviaAct, setIsTriviaAct] = useState<boolean>(false);
    const [isMatchAct, setIsMatchAct] = useState<boolean>(false);
    const [totalScore, setTotalScore] = useState<number>(100);
    var settings = {
        dots: false,
        infinite: false,
        speed: 500,
        slidesToShow: 2.5,
        responsive: [
            {
                breakpoint: 575,
                settings: {
                    slidesToShow: 1.8,
                }
            },
        ]
    };
    useEffect(() => {
        const scores = props.data.scores;
        function amount(item: any){
            return item.score;
        }
          
        function sum(prev: any, next: any){
            return prev + next;
        }
        if (scores.length) {
            const countVideo = scores.filter((e: ISaveScore) => e.activity === "VIEW_VIDEO");
            if (countVideo.length == 6) {
                setIsVideoAct(true);
            }
            const countLocker = scores.filter((e: ISaveScore) => e.activity === "VIEW_PROFILE");
            if (countLocker.length == 5) {
                setIsLockersAct(true);
            }
            scores.some((el: ISaveScore) => {
                if (el.activity === "PERSONALITY_QUIZ") {
                    setIsQuizzAct(true);   
                }
                if (el.activity === "TRIVIA_CHALLENGE") {
                    setIsTriviaAct(true);
                }
                if (el.activity === "MATCH_THREE") {
                    setIsMatchAct(true);
                }
            });
            setTotalScore(100 + scores.map(amount).reduce(sum));
        }
    }, [])
  return (
    <div className="profile-box">
        <div className="profile-box-title">
            <p className="ui">you’ve gained <span className="ur">{totalScore}</span>  points</p>
        </div>
        <div className="profile-box-slide">
            <h3 className="ur profile-box-slide-title">things to do</h3>
            <Slider {...settings} >
                {
                    !isMatchAct && 
                    <div 
                        className="profile-box-slide-item" 
                        onClick={() => {
                            TrackingUtils.SendGA(trackVars.eventName, trackVars.category, trackVars.profile.lable, `${trackVars.profile.action}_Match3`)
                            navigate("/laboratory", { state: {game: 'match3'} });
                        }}
                    >
                        <img src={TodoLaborMatch} alt="" />
                    </div>
                }
                {
                    !isQuizzAct && 
                    <div 
                        className="profile-box-slide-item" 
                        onClick={() => {
                            TrackingUtils.SendGA(trackVars.eventName, trackVars.category, trackVars.profile.lable, `${trackVars.profile.action}_PersonalityQuiz`)
                            navigate("/laboratory", { state: {game: 'personality-quiz'} })
                        }}
                    >
                        <img src={TodoLaborQuizz} alt="" />
                    </div>
                }
                {
                    !isTriviaAct && 
                    <div 
                        className="profile-box-slide-item" 
                        onClick={() => {
                            TrackingUtils.SendGA(trackVars.eventName, trackVars.category, trackVars.profile.lable, `${trackVars.profile.action}_TriviaChallenge`)
                            navigate("/laboratory", { state: {game: 'trivia'} })
                        }}
                    >
                        <img src={TodoLaborTrivia} alt="" />
                    </div>
                }
                {
                    !isVideoAct && 
                    <div 
                        className="profile-box-slide-item" 
                        onClick={() => {
                            TrackingUtils.SendGA(trackVars.eventName, trackVars.category, trackVars.profile.lable, `${trackVars.profile.action}_VisitTheatre`)
                            navigate("/theatre")}
                        }
                    >
                        <img src={TodoTheatre} alt="" />
                    </div>
                }
                {
                    !isLockersAct && 
                    <div 
                        className="profile-box-slide-item" 
                        onClick={() => {
                            TrackingUtils.SendGA(trackVars.eventName, trackVars.category, trackVars.profile.lable, `${trackVars.profile.action}_VisitLockers`)
                            navigate("/lockers")}
                        }
                    >
                        <img src={TodoLockers} alt="" />
                    </div>
                }
            </Slider>
        </div>
        <div className="profile-box-slide completed">
            <h3 className="ur profile-box-slide-title">completed activity</h3>
            <Slider {...settings} >
                <div className="profile-box-slide-item">
                    <img src={TodoCreate} alt="" />
                </div>
                {
                    isMatchAct && <div className="profile-box-slide-item">
                        <img src={TodoLaborMatch} alt="" />
                    </div>
                }
                {
                    isQuizzAct && <div className="profile-box-slide-item">
                        <img src={TodoLaborQuizz} alt="" />
                    </div>
                }
                {
                    isTriviaAct && <div className="profile-box-slide-item">
                        <img src={TodoLaborTrivia} alt="" />
                    </div>
                }
                {
                    isVideoAct && <div className="profile-box-slide-item">
                        <img src={TodoTheatre} alt="" />
                    </div>
                }
                {
                    isLockersAct && <div className="profile-box-slide-item">
                        <img src={TodoLockers} alt="" />
                    </div>
                }
            </Slider>
        </div>
    </div>
  )
}

export default ProfileBox