import React, { useEffect } from 'react'
import './index.scss';
import { QuizGame, TriviaGame, MatchGame, MQuizGame, MTriviaGame, MMatchGame } from '@components/reuseable/image';
import { showInView } from '@utils/showInView';

interface ILaboratoryPicker {
    game: any,
    init: string
}

const LaboratoryPicker = (props: ILaboratoryPicker) => {
  useEffect(() => {
    showInView();
  })
  const handleClickItem = (e: any) => {
    let items = document.querySelectorAll(".labor-picker-item-image");
    items.forEach((item: any) => {
        item.classList.remove("active");
    })
    e.target.classList.add("active");
  }
  return (
    <>
        <div onClick={handleClickItem} className="labor-picker-item">
            <img onClick={props.game} className={`labor-picker-item-image ${props.init == "personality-quiz" && "active"}`} data-game="personality-quiz" src={QuizGame} alt="" />
            <img onClick={props.game} className={`labor-picker-item-image mob ${props.init == "personality-quiz" && "active"}`} data-game="personality-quiz" src={MQuizGame} alt="" />
        </div>
        <div onClick={handleClickItem} className="labor-picker-item">
            <img onClick={props.game} className={`labor-picker-item-image ${props.init == "trivia" && "active"}`} data-game="trivia" src={TriviaGame} alt="" />
            <img onClick={props.game} className={`labor-picker-item-image mob ${props.init == "trivia" && "active"}`} data-game="trivia" src={MTriviaGame} alt="" />
        </div>
        <div onClick={handleClickItem} className="labor-picker-item">
            <img onClick={props.game} className={`labor-picker-item-image ${props.init == "match3" && "active"}`} data-game="match3" src={MatchGame} alt="" />
            <img onClick={props.game} className={`labor-picker-item-image mob ${props.init == "match3" && "active"}`} data-game="match3" src={MMatchGame} alt="" />
        </div>
    </>
  )
}

export default LaboratoryPicker