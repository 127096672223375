import LaboratoryMinigame from '@components/partials/Laboratory/Minigame';
import LaboratoryPicker from '@components/partials/Laboratory/Picker';
import { Col, Row } from 'antd';
import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom';
import './index.scss';
import LaborIcon1 from '@assets/images/labo_ic_1.png';
import LaborIcon2 from '@assets/images/labo_ic_2.png'; 
import LaborIcon3 from '@assets/images/labo_ic_3.png';
import Guide from '@components/reuseable/guide';
import { Cookie } from '@utils/cookie';
import Points from '@components/reuseable/score';
import { ScoreService } from '@services/score';
import { ISaveScore } from '@interfaces/score';
import { AuthService } from '@services/auth';
import LoginRemind from '@components/reuseable/login_remind';
import { TrackingUtils } from '@utils/gtag';
import { trackVars } from '@utils/trackingVariables';

const Laboratory = () => {
  const [isInGuide, setIsInGuide] = useState(true);
  const [isShowPoint, setIsShowPoint] = useState(false);
  const [pointTrivia, setPointTrivia] = useState<any>(0);
  const [pointMatch, setPointMatch] = useState<any>(0);
  const [isQuizCompleted, setIsQuizCompleted] = useState<boolean>();
  const [score, setScore]: any = useState();
  const navigate = useNavigate();
  const location = useLocation();
  const [minigame, setMinigame] = useState<string>(location?.state?.game !== undefined ? location.state.game : "personality-quiz");

  const [isShowLoginRemind, setIsShowLoginRemind] = useState(false);
  
  useEffect(() => {
    // if (!Cookie.Get("user_token")) {
    //   navigate("/")
    // }
    let content = document.querySelector(".labor");
    let guide = document.getElementById("guide");
    content && content.addEventListener('click', () => {
      if (guide?.classList.contains("active")) {
        TrackingUtils.SendGA(trackVars.eventName, trackVars.category, trackVars.laboratory.lable, `${trackVars.instruction}_Laboratory`)
      }
      setIsInGuide(false);
    });
  });

  const getProfile = async () => {
    try {
      const res = await AuthService.GetProfile();
      if (res) {
        const allScores = res.data.data.scores;
        allScores.some((el: ISaveScore) => {
          if (el.activity === "TRIVIA_CHALLENGE") {
            setPointTrivia(el.score);
          }
          if (el.activity === "MATCH_THREE") {
            setPointMatch(el.score);
          }
          if (el.activity === "PERSONALITY_QUIZ") {
            setIsQuizCompleted(true);
          }
        })
      }
    }
    catch (err) {
      console.log(err)
    }
    finally {

    }
  }

  useEffect(() => {
    if (Cookie.Get("user_token")) {
      getProfile();
    }
    if (Cookie.Get("temp_game") == "personality-quiz") {
      SaveScroresQuiz(Cookie.Get("temp_game"));
      setScore("100");
    } else if (Cookie.Get("temp_game") == "trivia") {
      setIsShowPoint(true);
      SaveScroresTrivia("trivia", parseFloat(Cookie.Get("score_trivia")));
      setScore(`${Cookie.Get("score_trivia")}`)
    } else if (Cookie.Get("temp_game") == "match3") {
      setIsShowPoint(true);
      SaveScroresTrivia("match3", parseFloat(Cookie.Get("score_match3")));
      setScore(`${Cookie.Get("score_match3")}`)
    }
  }, [])

  const SaveScroresQuiz = async (id: string) => {
    const data: ISaveScore = {
        score: 100,
        activity: "PERSONALITY_QUIZ",
        target: id
    }
    try {
        const res = await ScoreService.SaveScore(data);
        if (res) {
          if (!isQuizCompleted) {
            setIsShowPoint(true);
          }
        }
      }
    catch(err) {
      console.log(err)
    }
    finally {
      Cookie.Remove("temp_game");
    }
  }

  const SaveScroresMatch = async (id: string, score: number) => {
    setPointMatch(score);
    const data: ISaveScore = {
        score: score,
        activity: "MATCH_THREE",
        target: id
    }
    try {
        const res = await ScoreService.SaveScore(data);
        if (res) {
          Cookie.Remove("score_match3");
          // setIsShowPoint(true);
        }
      }
    catch(err) {
      console.log(err)
    }
    finally {
      Cookie.Remove("temp_game");
    }
  }

  const SaveScroresTrivia = async (id: string, score: number) => {
    setPointTrivia(score);
    const data: ISaveScore = {
        score: score,
        activity: "TRIVIA_CHALLENGE",
        target: id
    }
    try {
        const res = await ScoreService.SaveScore(data);
        if (res) {
          Cookie.Remove("score_trivia");
          // setIsShowPoint(true);
        }
      }
    catch(err) {
      console.log(err)
    }
    finally {
      Cookie.Remove("temp_game");
    }
  }

  const [isGameEnd, setIsGameEnd] = useState<boolean>(false);

  let interval: any;
  let time: number = 0;
  useEffect(() => {
    let gameIframe: any = document.querySelector(`.labor-game-frame-container iframe#${minigame}`);
    function game () {
      if (gameIframe?.contentWindow?.isEnded) {
        setIsGameEnd(true);
        // console.log('game end');
        if (minigame == "personality-quiz" && gameIframe.contentWindow.quizCompletion) {
          if (Cookie.Get("user_token")) {
            SaveScroresQuiz("personality-quiz");
            setScore("100");
          } else {
            setIsShowLoginRemind(true);
            Cookie.Set("temp_game","personality-quiz");
          }
        }
        if (minigame == "trivia") {
          let scoreTrivia = gameIframe.contentWindow.Play.score;
          if (scoreTrivia && pointTrivia < scoreTrivia) {
            if (Cookie.Get("user_token")) {
              SaveScroresTrivia("trivia", scoreTrivia);
              setIsShowPoint(true);
              setScore(`${scoreTrivia}`);
            } else {
              setIsShowLoginRemind(true);
              Cookie.Set("temp_game","trivia");
              Cookie.Set("score_trivia", scoreTrivia);
            }
          }
        }
        if (minigame == "match3") {
          let scoreMatch = gameIframe.contentWindow.totalPoint;
          if (scoreMatch && pointMatch < scoreMatch) {
            if (Cookie.Get("user_token")) {
              setIsShowPoint(true);
              setScore(`${scoreMatch}`);
              SaveScroresMatch("match", scoreMatch);
            } else {
              setIsShowLoginRemind(true);
              Cookie.Set("temp_game","match3");
              Cookie.Set("score_match3", scoreMatch);
            }
          }
        }
        clearInterval(interval);
        return;
      } else {
        // console.log('game on');
      }
    }
    if (isGameEnd == false) {
      interval = setInterval(game, 1000);
    }
    gameIframe.onload = () => {
      setInterval(() => {
        // console.log('game on load');
        setIsGameEnd(gameIframe?.contentWindow?.isEnded);
      }, 1000)
    }
  }, [isGameEnd, minigame])

  useEffect(() => {
    let gameIframe: any = document.querySelector(`.labor-game-frame-container iframe#${minigame}`);
    let trackData = gameIframe.id == "personality-quiz" ? "PersonalityQuiz" :
      gameIframe.id == "trivia" ? "TriviaChallenge" :
      gameIframe.id == "match3" ? "Match3" : ""
    let a = setInterval(() => {
      time += 5;
      if (time == 120) {
        clearInterval(a);
      }
      TrackingUtils.SendGA(trackVars.eventName, trackVars.game.category, trackVars.game.lable, `${trackVars.game.lable} ${time} seconds_${trackData}`)
    }, 5000)
    return () => {
      clearInterval(a);
    }
  }, [minigame])

  const handlePicker = (e: any) => {
    time = 0;
    clearInterval(interval);
    const data = e.target.getAttribute('data-game');
    let trackData = data == "personality-quiz" ? "PersonalityQuiz" :
      data == "trivia" ? "TriviaChallenge" :
      data == "match3" ? "Match3" : ""
    TrackingUtils.SendGA(trackVars.eventName, trackVars.category, trackVars.laboratory.lable, `${trackVars.laboratory.action}_${trackData}`)
    setMinigame(data);
  }

  return (
    <div className='labor'>
      <h2 className="ui">EXPLORE THE LABORATORY</h2>
      <div className="labor-container">
        <Row style={{height: '100%'}}>
          <Col xs={24} sm={8} xl={8} className="labor-picker">
            <LaboratoryPicker game={handlePicker} init={minigame} />
          </Col>
          <Col xs={24} sm={16} xl={8} className="labor-game">
            <LaboratoryMinigame game={minigame} />
            <img src={LaborIcon1} alt="" className="labor-game-icon" />
            <img src={LaborIcon2} alt="" className="labor-game-icon right" />
          </Col>
          <Col sm={0} xl={8} className="labor-icon">
            <img src={LaborIcon3} alt="" className="labor-icon-img top" />
            <img src={LaborIcon2} alt="" className="labor-icon-img bot" />
          </Col>
        </Row>
      </div>
      <Guide isHome={false} isShow={isInGuide} section="labor" text="Hello! I'm Frankie Stein - a Monster High student. Welcome to my Laboratory: This is where you can play exciting minigames and gain points. Try your best and you can have the chance to win amazing Monster High prizes!" />
      <Points close={() => setIsShowPoint(false)} point={score} isShowing={isShowPoint} />
      <LoginRemind 
        isShowing={isShowLoginRemind} 
        close={() => {
          Cookie.Remove("temp_game");
          Cookie.Remove("score_trivia");
          Cookie.Remove("score_match3");
          setIsShowLoginRemind(false);
        }}
      />
    </div>
  )
}

export default Laboratory