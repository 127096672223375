import SlideSlick from '@components/partials/Theatre/Slide';
import Guide from '@components/reuseable/guide';
import LoginRemind from '@components/reuseable/login_remind';
import Points from '@components/reuseable/score';
import { ISaveScore } from '@interfaces/score';
import { ScoreService } from '@services/score';
import { Cookie } from '@utils/cookie';
import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router';
import './index.scss'
import { TrackingUtils } from '@utils/gtag';
import { trackVars } from '@utils/trackingVariables';

const Theatre = () => {
  const navigate = useNavigate();
  const [isInGuide, setIsInGuide] = useState(true);
  const [isOpenPoints, setIsOpenPoints] = useState(false);
  const [title, setTitle] = useState<string>("Welcome To Monster High");
  const [isShowLoginRemind, setIsShowLoginRemind] = useState(false);

  useEffect(() => {
    // if (!Cookie.Get("user_token")) {
    //   navigate("/")
    // }
    if (Cookie.Get("temp_theatre")) {
      SaveScrores(Cookie.Get("temp_theatre"));
    }
    let content = document.querySelector(".theatre");
    let guide = document.getElementById("guide");
    content && content.addEventListener('click', () => {
      if (guide?.classList.contains("active")) {
        TrackingUtils.SendGA(trackVars.eventName, trackVars.category, trackVars.theatre.lable, `${trackVars.instruction}_Theatre`)
      }
      setIsInGuide(false);
    });
  });
  const SaveScrores = async (id: string) => {
    const data: ISaveScore = {
        score: 50,
        activity: "VIEW_VIDEO",
        target: id
    }
    try {
        const res = await ScoreService.SaveScore(data);
        if (res) {
          setIsOpenPoints(true);
        }
      }
    catch(err) {
      console.log(err)
    }
    finally {
      Cookie.Remove("temp_theatre");
    }
}
  const handleTitle = (data: string) => {
    setTitle(data);
  }
  return (
    <div className="theatre">
        <h2 className="ui">monster high videos</h2>
        <div className="theatre-container">
            <SlideSlick title={handleTitle} remind={() => setIsShowLoginRemind(true)} showPoints={(data: boolean) => setIsOpenPoints(true)} />
            <div className="theatre-title">
              <h3 className='g-book active'>{title}</h3>
            </div>
        </div>
        <Guide isHome={false} isShow={isInGuide} section="theatre" text="Hi there, my name is Clawdeen Wolf, also a student at Monster High! Welcome to the Theatre! Here you can watch videos of the fabulous adventures of our famous students!" />
        <Points isShowing={isOpenPoints} close={() => setIsOpenPoints(false)} point="50" />
        <LoginRemind 
          isShowing={isShowLoginRemind} 
          close={() => {
            setIsShowLoginRemind(false);
            Cookie.Remove("temp_theatre");
          }}
        />
    </div>
  )
}

export default Theatre