import React, { useState } from 'react'
import './index.scss';
import { Spin } from 'antd';
import Badge from '@components/reuseable/badge';
import Step2BtnText from '@assets/images/step-2-btn-text.png';
import { SubmitBtn, Shape_1, Shape_2, Shape_3, Avatar_1, Avatar_2, Avatar_3, Avatar_4, Avatar_5, Color_1, Color_2, Color_3, Color_4, Color_5 } from '@components/reuseable/image';
import BadgeAPI from '@components/reuseable/badgeAPI';
import FinishBtnText from '@assets/images/finish-btn-text.png';

interface IEditBadge {
  submit: any,
  loading: boolean,
  data: any
}


const EditBadge = (props: IEditBadge) => {
  const [shape, setShape] = useState<string>(props.data.badge.frame);
  const [avatar, setAvatar] = useState<string>(props.data.badge.image);
  const [color, setColor] = useState<string>(props.data.badge.background);
  const onClickShape = (e: any) => {
    let data = e.target.getAttribute("data-shape");
    setShape(data);
  }
  const onClickAvatar = (e: any) => {
    let data = e.target.getAttribute("data-avatar");
    setAvatar(data);
  }
  const onClickColor = (e: any) => {
    let data = e.target.getAttribute("data-color");
    setColor(data);
  }
  return (
    <div className="edit-badge-box">
      <div className="group">
      <div className="group-split group-left">
        {/* Choose Shape */}
        <div className="group-left-item">
          <div className="group-left-item-title">
            <p className='tb'>Choose a shape:</p>
          </div>
          <div className="group-left-item-options">
            <img className={`${shape == "rect" ? "active" : ""}`} onClick={onClickShape} data-shape="rect" src={Shape_1} alt="" />
            <img className={`border ${shape == "round" ? "active" : ""}`} onClick={onClickShape} data-shape="round" src={Shape_2} alt="" />
            <img className={`circle ${shape == "circle" ? "active" : ""}`} onClick={onClickShape} data-shape="circle" src={Shape_3} alt="" />
          </div>
        </div>
        {/* Choose Avatar */}
        <div className="group-left-item">
          <div className="group-left-item-title">
            <p className='tb'>Choose an image:</p>
          </div>
          <div className="group-left-item-options border">
            <img className={`${avatar == "/avatar/1.png" ? "active" : ""}`} onClick={onClickAvatar} data-avatar="/avatar/1.png" src={Avatar_1} alt="" />
            <img className={`${avatar == "/avatar/2.png" ? "active" : ""}`} onClick={onClickAvatar} data-avatar="/avatar/2.png" src={Avatar_2} alt="" />
            <img className={`${avatar == "/avatar/3.png" ? "active" : ""}`} onClick={onClickAvatar} data-avatar="/avatar/3.png" src={Avatar_3} alt="" />
            <img className={`${avatar == "/avatar/4.png" ? "active" : ""}`} onClick={onClickAvatar} data-avatar="/avatar/4.png" src={Avatar_4} alt="" />
            <img className={`${avatar == "/avatar/5.png" ? "active" : ""}`} onClick={onClickAvatar} data-avatar="/avatar/5.png" src={Avatar_5} alt="" />
          </div>
        </div>
        {/* Choose Badge */}
        <div className="group-left-item">
          <div className="group-left-item-title">
            <p className='tb'>Choose a colour :</p>
          </div>
          <div className="group-left-item-options circle">
            <img className={`${color == "/badge/1.png" ? "active" : ""}`} onClick={onClickColor} data-color="/badge/1.png" src={Color_1} alt="" />
            <img className={`${color == "/badge/2.png" ? "active" : ""}`} onClick={onClickColor} data-color="/badge/2.png" src={Color_2} alt="" />
            <img className={`${color == "/badge/3.png" ? "active" : ""}`} onClick={onClickColor} data-color="/badge/3.png" src={Color_3} alt="" />
            <img className={`${color == "/badge/4.png" ? "active" : ""}`} onClick={onClickColor} data-color="/badge/4.png" src={Color_4} alt="" />
            <img className={`${color == "/badge/5.png" ? "active" : ""}`} onClick={onClickColor} data-color="/badge/5.png" src={Color_5} alt="" />
          </div>
        </div>
      </div>
      <div className="group-split group-right">
        <BadgeAPI shape={shape} color={color} avatar={avatar} />
      </div>
      </div>
      <button className="btn" onClick={() => props.submit({
        frame: shape,
        background: color,
        image: avatar
      })}>
        <div className="btn-group">
            {
              props.loading ? <Spin /> : <img src={FinishBtnText} />
            }
        </div>
      </button>
    </div>
  )
}

export default EditBadge