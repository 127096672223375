export const usernames = [  
    {
        value: 'Spooky Banshee',
        label: 'Spooky Banshee',
    },
    {
        value: 'Haunting Ghost',
        label: 'Haunting Ghost',
    },
    {
        value: 'Freaky Grim reaper',
        label: 'Freaky Grim reaper',
    }, 
    {
        value: 'Ghoulish Mummie',
        label: 'Ghoulish Mummie',
    },
    {
        value: 'Creepy Phantom',
        label: 'Creepy Phantom',
    },
    {
        value: 'Monstrous Skeleton',
        label: 'Monstrous Skeleton',
    },
    {
        value: 'Menacing Vampire',
        label: 'Menacing Vampire',
    },
    {
        value: 'Dangerous Zoombie',
        label: 'Dangerous Zoombie',
    },
    {
        value: 'Fiendish Skeleton',
        label: 'Fiendish Skeleton',
    },
    {
        value: 'Mischievous Phantom',
        label: 'Mischievous Phantom',
    },
    {
        value: 'Grisly Vampire',
        label: 'Grisly Vampire',
    },
    {
        value: 'Petrifying Mummie',
        label: 'Petrifying Mummie',
    },
    {
        value: 'Fearsome Zombie',
        label: 'Fearsome Zombie',
    },
    {
        value: 'Mysterious Grim reaper',
        label: 'Mysterious Grim reaper',
    },
    {
        value: 'Eerie Banshee',
        label: 'Eerie Banshee',
    },
    {
        value: 'Frightful Ghost',
        label: 'Frightful Ghost',
    }
]