import { AUTH_ENDPOINT } from "@enums/auth";
import { ILoginData, IRegisterData, IToken } from "@interfaces/auth";
import { IBadge } from "@interfaces/badge";
import { Axios } from "@utils/axios";
import { Cookie } from "@utils/cookie";
import { AxiosPromise } from "axios";

function getParam(data: any) {
  if (data) {
    return ;
  }
}

class AuthService {
  
  isLogined: boolean = false;

  async Login(data: ILoginData): Promise<AxiosPromise> {
    
    try {
      const res = await Axios.post(AUTH_ENDPOINT.LOGIN, data);
      this.isLogined = true;
      if (res) {
        Cookie.Set("username", data.username, new Date(res?.data.data.expiredAt));
        Cookie.Set("id", data.serial, new Date(res?.data.data.expiredAt));
      }
      
      return res;
    } catch (error: any) {
      this.isLogined = false;
      return Promise.reject(error);
    }
  }

  Register(data: IRegisterData): AxiosPromise {
    return Axios.post(AUTH_ENDPOINT.REGISTER, data);
  }

  GetSerial(data: object): AxiosPromise {
    return Axios.post(AUTH_ENDPOINT.GET_SERIAL, data);
  }

  UpdateBadge(data: IBadge): AxiosPromise {
    return Axios.post(AUTH_ENDPOINT.UPDATE_BADGE, data, { headers: {Authorization: `Bearer ${Cookie.Get("user_token")}` } });
  }

  GetProfile(): AxiosPromise {
    return Axios.get(AUTH_ENDPOINT.GET_PROFILE, { headers: {Authorization: `Bearer ${Cookie.Get("user_token")}` } });
  }

  SaveToken(data: IToken) {
    const today = new Date();
    // const expire = new Date(today.getTime() + parseInt(data.expiredAt) * 60000);
    const expire = new Date(data.expiredAt);

    Cookie.Set("user_token", data.accessToken, expire);
    Cookie.Set("user_refresh_token", data.refreshToken);
  }

  SaveTime(data: any) {
    Cookie.Set("time_change_type", data);
  }

  RefreshToken(): AxiosPromise {
    return Axios.post(AUTH_ENDPOINT.REFRESHTOKEN, {refreshToken: `${Cookie.Get("user_refresh_token")}`}, { headers: {Authorization: `Bearer ${Cookie.Get("user_refresh_token")}` } });
  }

  isAuthenticated() {
    if (this.isLogined) {
      console.log('1');
      return true;
    } else if (!!Cookie.Get("user_token") || !!Cookie.Get("user_refresh_token")) {
      console.log('2');
      return true;
    } else {
      console.log('3')
      return false;
    }
  }
}
const authService = new AuthService();
export { authService as AuthService };
