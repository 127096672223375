import Guide from '@components/reuseable/guide';
import { HomeBtnCreate, HomeBtnHandbook, HomeGuideCharacter, HomeGuideName } from '@components/reuseable/image';
import { useNavigate } from 'react-router';
import './index.scss';

interface IHomeGuide {
    openBook: any;
}

const HomeGuide = (props: IHomeGuide) => {
    const navigate = useNavigate();
    return (
        <div className="home-guide">
            <img onClick={() => navigate("/create-account")} className="home-guide-btn left" src={HomeBtnCreate} alt="" />
            <div className="home-guide-mid">
                <div className="home-guide-mid-character">
                    <img src={HomeGuideCharacter} alt="" />
                    <img src={HomeGuideName} alt="" />
                </div>
                <div className="home-guide-mid-text">
                    <p className="g-book">Hey kids, welcome to the Monster High website! I’m	Draculaura, a student at Monster High. Come inside and explore the campus, where you can play games, talk to my friends, watch videos and WIN prizes!</p>
                </div>
                {/* <Guide isHome={true} isShow={true} section="home" text="Welcome to the Theatre! Here you can watch videos of the fabulous adventures of our famous students!" /> */}
            </div>
            <img onClick={props.openBook} className="home-guide-btn right" src={HomeBtnHandbook} alt="" />
        </div >
    )
}

export default HomeGuide