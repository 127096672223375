import React, { useState } from 'react'
import Handbook from "@assets/images/handbook.png";
import CloseHandbook from "@assets/images/close-handbook.png";
import MBook1 from "@assets/images/m-book-1.png";
import MBook2 from "@assets/images/m-book-2.png";
import MBookNext from "@assets/images/m-book-next.png";
import MBookPrev from "@assets/images/m-book-prev.png"

import "./index.scss";

interface IHandBook {
    isShowing: boolean;
    closeBook: any
}

const HandBook = (props: IHandBook) => {
  const [bookPage, setBookPage] = useState(1);
  const handleClick = (data: string) => {
    if (data == "next") {
      setBookPage(2);
    } else {
      setBookPage(1);
    }
  }
  const handleClose = () => {
    props.closeBook();
    setTimeout(() => {
      setBookPage(1);
    }, 300)
  }
  return (
    <div className={`handbook ${props.isShowing ? "active" : ""}`}>
        <div className="handbook-container">
            <img className='book' src={Handbook} alt="" />
            <div className="m-book">
              <img className={`m-book-img page2 ${bookPage == 1 && "active"}`} src={MBook2} alt="" />
              <img className={`m-book-img page1 ${bookPage == 2 && "active"}`} src={MBook1} alt="" />
            </div>
            <div className={`books ${bookPage == 2 && "active"}`}>
              <div className="books-cover">
                {/* <img className={`m-book-img page1 ${bookPage == 2 && "active"}`} src={MBook1} alt="" /> */}
              </div>
              <div className="books-content">
                {/* <img className={`m-book-img page2 ${bookPage == 1 && "active"}`} src={MBook2} alt="" /> */}
              </div>
            </div>
            {/* <div className={`m-book page1 ${bookPage == 2 && "active"}`}>
            </div> */}
            <img onClick={() => handleClick("next")} className={`m-book-nav next ${bookPage == 2 && "hide"}`} src={MBookNext} alt="" />
            <img onClick={() => handleClick("prev")} className={`m-book-nav prev ${bookPage == 1 && "hide"}`} src={MBookPrev} alt=""/>
            <img onClick={handleClose} className='close' src={CloseHandbook} alt="" />
        </div>
    </div>
  )
}

export default HandBook