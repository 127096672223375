import './index.scss'
import React, { useEffect, useRef, useState } from 'react';
import './index.scss'
import LoginBtn from "@assets/images/login-btn.png";
import CreateBtn from "@assets/images/login-create-btn.png";
import DownIcon from "@assets/images/dropdown-icon.png";
import LoginBtnText from "@assets/images/btn-login-text.png"
import { Input, Select, Spin } from 'antd';

import OtpInput from 'react-otp-input';
import { AuthService } from '@services/auth';
import { usernames } from '@mocks/users';
import { useNavigate, useOutletContext } from 'react-router';
import { Cookie } from '@utils/cookie';
import { TrackingUtils } from '@utils/gtag';
import { trackVars } from '@utils/trackingVariables';

const Login = () => {
  const navigate = useNavigate();
  const [username, setUsername] = useState<string>();
  const [password, setPassword] = useState<string>();
  const [serial, setSerial] = useState<string>();
  const [isLoading, setIsLoading] = useState<boolean>();
  const [disableBtn, setDisableBtn] = useState(true);
  const [errorSerial, setErrorSerial] = useState(false);
  const [errorPwd, setErrorPwd] = useState(false);
  const [errorUser, setErrorUser] = useState(false);
  const {setIsLogined} = useOutletContext<any>();

  const onChange = (value: string) => {
    TrackingUtils.SendGA(trackVars.eventName, trackVars.category, trackVars.login.lable, `${trackVars.login.action}_${value}`)
    setUsername(value);
    setErrorUser(false);
    setErrorPwd(false);
    setErrorSerial(false);
  };

  const handleChangeOTP = (data: any) => {
    setPassword(data);
    setErrorUser(false);
    setErrorPwd(false);
    setErrorSerial(false);
  }

  const handleChangeSerial = (data: any) => {
    setSerial(data);
    setErrorUser(false);
    setErrorPwd(false);
    setErrorSerial(false);
  }

  useEffect(() => {
    if (parseInt(`${password?.length}`) == 4 && parseInt(`${serial?.length}`) == 4 && username && !errorPwd && !errorSerial && !errorUser) {
      setDisableBtn(false);
    } else {
      setDisableBtn(true);
    }
  }, [username, password, serial]);

  const handleSubmitLogin = async () => {
    TrackingUtils.SendGA(trackVars.eventName, trackVars.category, trackVars.login.lable, `${trackVars.login.action}_Submit`)
    setIsLoading(true);
    if (username && password && serial && parseInt(`${password?.length}`) == 4 && parseInt(`${serial?.length}`) == 4 && !errorPwd && !errorSerial && !errorUser) {
      const data = {
        username: username,
        password: password,
        serial: parseFloat(serial)
      };
      try {
        const res = await AuthService.Login(data);
        if (res) {
          setIsLogined(true);
          AuthService.SaveToken(res.data.data);
          if (Cookie.Get("temp_locker")) {
            navigate("/lockers");
          } else if (Cookie.Get("temp_theatre")) {
            navigate("/theatre");
          } else if (Cookie.Get("temp_game")) {
            navigate("/laboratory");
          } else {
            navigate("/");
          }
        }
      }
      catch (err) {
        console.log(err);
        setErrorPwd(true);
        setErrorUser(true);
        setErrorSerial(true);
        setDisableBtn(true);
      }
      finally {
        setIsLoading(false);
      }
    }

  }
  return (
    <div className="loginpage">
      <h2 className="ur">welcome back to monster high!</h2>
      <div className="loginpage-box">
      <div className="form">
        <div className="group-top">
          <div className="form-input select">
            <p className='form-input-label ur'>Username</p>
            <Select
              suffixIcon={<img src={DownIcon}></img>}
              className={`form-input-select ${errorUser ? "error" : ""}`}
              placeholder="Pick a username"
              optionFilterProp="children"
              // onChange={onChange}
              onSelect={onChange}
              options={usernames}
              listHeight={180}
              dropdownAlign={{ offset: [0, 0] }}
              dropdownStyle={{zIndex: 11}}
            />
            <div className="form-input serial">
              <p className='form-input-label serial tb'>#</p>
              <form onKeyPress={(e) => {
                if (!disableBtn && e.key === 'Enter') {
                  handleSubmitLogin();
                }
              }}>
                <OtpInput
                  value={serial}
                  onChange={handleChangeSerial}
                  numInputs={4}
                  isInputNum={true}
                  shouldAutoFocus={false}
                  className={`form-input-serial ${errorSerial ? "error" : ""}`}
                  containerStyle={"form-serial_wrap"}
                />
              </form>
            </div>
          </div>
          <div className="form-input code">
            <p className='form-input-label ur'>passcode</p>
            <form onKeyPress={(e) => {
              if (!disableBtn && e.key === 'Enter') {
                handleSubmitLogin();
              }
            }}>
              <OtpInput
                value={password}
                onChange={handleChangeOTP}
                numInputs={4}
                isInputNum={true}
                shouldAutoFocus={false}
                className={`form-input-otp ${errorPwd ? "error" : ""}`}
                containerStyle={"form-otp_wrap"}
              />
            </form>
          </div>
        </div>
        <button disabled={disableBtn} className="form-confirm" onClick={handleSubmitLogin}>
          <div className="form-confirm-btn">
            {
              isLoading ? <Spin /> : <img src={LoginBtnText} alt="" />
            }
          </div>
          {/* <img className={`${disableBtn ? "disable" : ""}`} src={LoginBtn} alt=""/> */}
        </button>
        <div className="loginpage-box-line"></div>
        <div className="loginpage-box-sub">
          <p className='tb'>Don’t have an account yet?</p>
        </div>
        <button className='loginpage-box-create'>
          <img onClick={() => {
            TrackingUtils.SendGA(trackVars.eventName, trackVars.category, trackVars.login.createAccount.label, trackVars.login.createAccount.action)
            navigate("/create-account");
          }} src={CreateBtn} alt="" />
        </button>
      </div>
      </div>
    </div>
  )
}

export default Login