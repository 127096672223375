import React from 'react'
import './index.scss'
import ViewProfileBtn from "@assets/images/view-profile-btn.png";
import CloseHandbook from "@assets/images/close-handbook.png";
import { useNavigate } from 'react-router';
import { TrackingUtils } from '@utils/gtag';
import { trackVars } from '@utils/trackingVariables';

interface IPoints {
    close: any,
    isShowing: boolean,
    point: string
}

const Points = (props: IPoints) => {
    const navigate = useNavigate();
  return (
    <div className={`points ${props.isShowing ? "active" : ""}`}>
        <div className="points-box">
            <p className="tb">You’ve gained <span className="tb">+{props.point}</span> points!<br></br>Complete other activities to gain more points.</p>
            <img 
              className='btn' 
              onClick={() => {
                TrackingUtils.SendGA(trackVars.eventName, trackVars.category, "General", "Pop Up_ViewProfile");
                navigate("/profile");
              }} 
              src={ViewProfileBtn} alt="" />
            <img onClick={() => {
              TrackingUtils.SendGA(trackVars.eventName, trackVars.category, "General", "Pop Up_Close");
              props.close();
            }} className='close' src={CloseHandbook} alt="" />
        </div>
    </div>
  )
}

export default Points