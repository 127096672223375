import HomeDoll from '@components/partials/HomePage/Dolls';
import HomeGuide from '@components/partials/HomePage/Guide';
import HandBook from '@components/partials/HomePage/Handbook';
import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router';
import './index.scss';
import { HomeBtnCreate } from '@components/reuseable/image';
import { VideoIntroHome } from '@components/reuseable/image';
import { Cookie } from '@utils/cookie';
import LoginRemind from '@components/reuseable/login_remind';
import Guide from '@components/reuseable/guide';
import { TrackingUtils } from '@utils/gtag';
import { trackVars } from '@utils/trackingVariables';

const HomePage = () => {
  const navigate = useNavigate();
  const [isInGuide, setIsInGuide] = useState(window.sessionStorage.getItem("Intro") ? true : false);
  const [showBook, setShowBook] = useState(false);
  const [isIntro, setIsIntro] = useState(window.sessionStorage.getItem("Intro") ? window.sessionStorage.getItem("Intro") : "true");
  const [isShowLoginRemind, setIsShowLoginRemind] = useState(false);

  let user = Cookie.Get("username");
  let id = Cookie.Get("id");
  const callBackOpenBook = () => {
    TrackingUtils.SendGA(trackVars.eventName, trackVars.category, trackVars.homepage.lable, `${trackVars.homepage.action}_Handbook`)
    setShowBook(true);
  };
  const callBackCloseBook = () => {
    setShowBook(false);
  };
  useEffect(() => {
   let video = document.querySelector<HTMLVideoElement>('.homepage-video-vid');
   let div_video = document.querySelector('.homepage-video');
   video?.addEventListener("timeupdate", () => {
    let watched: any = video?.currentTime;
    let duration: any = video?.duration;
    if ((watched * 100 / duration) > 90) {
        video?.classList.add("closing");
        div_video?.classList.add("closing");
      }
    })
    video?.addEventListener("ended", () => {
      setIsInGuide(true);
      setIsIntro("false");
      window.sessionStorage.setItem("Intro", "false");
    });
  })
  useEffect(() => {
    let content = document.querySelector(".homepage");
    content && content.addEventListener('click', () => {
      setIsInGuide(false);
    });
  }, [])
  return (
    <div className="homepage">
      {
        isIntro == "true" ? 
        <div className="homepage-video">
          <video className='homepage-video-vid' muted autoPlay playsInline><source src={VideoIntroHome} type='video/mp4' /></video>
        </div> :
        <>
          <HomeDoll visitPage={() => setIsShowLoginRemind(true)} />
          <HomeGuide openBook={callBackOpenBook} />
          <HandBook isShowing={showBook} closeBook={callBackCloseBook} />
          {
            !(user && id) ? 
              <img 
                onClick={() => {
                  TrackingUtils.SendGA(trackVars.eventName, trackVars.category, trackVars.homepage.lable, `${trackVars.homepage.action}_CreateID`)
                  navigate("/create-account");
                }} 
                className="homepage-create" 
                src={HomeBtnCreate} alt="" 
              /> : ""
          }
        </>
      }
      <LoginRemind isShowing={isShowLoginRemind} close={() => setIsShowLoginRemind(false)}/>
      <Guide isHome={false} isShow={isInGuide} section="homepage" text="Hey kids, welcome to the Monster High website! I’m	Draculaura, a student at Monster High. Come inside and explore the campus, where you can play games, talk to my friends, watch videos and WIN prizes!" />
    </div>
  )
}

export default HomePage