import axios, { AxiosInstance } from 'axios';

/**
 * Create Axios Instance
 */
const Axios: AxiosInstance = axios.create({
  baseURL: `${process.env.REACT_APP_API_PROTOCOL}://${process.env.REACT_APP_API_DOMAIN}/${process.env.REACT_APP_API_PREFIX}`,
  headers: {
    "content-type": "application/json",
  },
});

const AxiosNotHavePrefix: AxiosInstance = axios.create({
  baseURL: `${process.env.REACT_APP_API_PROTOCOL}://${process.env.REACT_APP_API_DOMAIN}/api`,
  headers: {
    "content-type": "application/json",
  },
});

export { Axios, AxiosNotHavePrefix }