export const trackVars = {
    category: "Engagement",
    eventName: "select_content",
    instruction: "ClickInstruction",
    video: {
        category: "Video Interaction",
        action: {
            clickPlay: "Play Clicked",
            videoplayed: "Video played to",
            soundOn: "PlaySoundOn_Clicked",
            soundOff: "PlaySoundOff_Clicked",
            paused: "Pause_Clicked"
        },
        interaction: "Video Interaction",
    },
    homepage: {
        content_type: "Engagement__Homepage__Homepage",
        lable: "Homepage",
        action: "Homepage"
    },
    login: {
        content_type: "Engagement__Login__LoginUsername",
        lable: "Login",
        action: "LoginUsername",
        createAccount: {
            content_type: "Engagement__Login__CreateAccount",
            label: "Login",
            action: "CreateAccount"
        }
    },
    studentID: {
        content_type: "Engagement__StudentID__CreateUsername",
        lable: "StudentID",
        action: "CreateUsername"
    },
    laboratory: {
        content_type: "Engagement__Laboratory__ClickMenu",
        lable: "Laboratory",
        action: "ClickMenu"
    },
    theatre: {
        content_type: "Engagement__Theatre",
        lable: "Theatre",
        action: ""
    },
    lockers: {
        lable: "Lockers",
    },
    profile: {
        lable: "Profile",
        action: "ClickProfile"
    },
    game: {
        category: "Mini Game",
        lable: "Game Dwell"
    }
}