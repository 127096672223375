import BadgeAPI from '@components/reuseable/badgeAPI'
import React from 'react'
import EditBtn from "@assets/images/profile-edit-btn.png";
import './index.scss'
import { IProfile } from '@interfaces/auth';
import { useNavigate, useOutletContext } from "react-router-dom";
import { Cookie } from '@utils/cookie';

interface IProfileFlag {
    data: IProfile,
    click: any
}

const ProfileFlag = (props: IProfileFlag) => {
    const router = useNavigate();
    const {setIsLogined} = useOutletContext<any>();
    const handleSignOut = () => {
        Cookie.Remove("user_token");
        Cookie.Remove("user_refresh_token");
        Cookie.Remove("id");
        Cookie.Remove("username");
        router("/");
        setIsLogined(false);
    }
  return (
    <div className="profile-flag">
        <div className="profile-flag-title">
            <h4 className='ui'>Welcome to your profile,</h4>
            <h3 className='ur'>{props.data.fullName}</h3>
        </div>
        <div className="profile-flag-badge">
            <BadgeAPI shape={props.data.badge.frame} color={props.data.badge.background} avatar={props.data.badge.image} />
            <div className="profile-flag-badge-title">
            <h4 className='ui'>Welcome to your profile,</h4>
            <h3 className='ur'>{props.data.fullName}</h3>
        </div>
        </div>
        <div className="profile-flag-group">
            <div className="profile-flag-btn" onClick={props.click}>
                <img src={EditBtn} alt="" />
            </div>
            <div className="profile-flag-line"></div>
            <div className="profile-flag-signout" onClick={handleSignOut}>
                <h3 className="ui">SIGN OUT</h3>
            </div>
        </div>
    </div>
  )
}

export default ProfileFlag