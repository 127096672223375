import { Cookie } from '@utils/cookie';
import React, { useState } from 'react'
import './index.scss';

interface IBadge {
  dataS2: any,
  dataS3: any,
  step: number,
  setShape: any,
  setAvatar: any,
  setColor: any
}

const Badge = (props: IBadge) => {
  return (
    <>
      {
        props.step == 2 ? 
        <div className={`badge ${props.setColor}`}>
          <div className={`badge-avatar ${props.setShape} ${props.setAvatar}`}></div>
          <div className="badge-info">
            <p className='ur'>{props.dataS2 !== undefined ? props.dataS2?.username : Cookie.Get("username")}</p>
            <p className='ur'>{`#${props.dataS2 !== undefined ? props.dataS2?.serial : Cookie.Get("id")}`}</p>
          </div>
        </div>
        : props.step == 3 ? 
        <div className={`badge ${props.dataS3.color}`}>
          <div className={`badge-avatar ${props.dataS3.shape} ${props.dataS3.avatar}`}></div>
          <div className="badge-info">
            <p className='ur'>{props.dataS3.username !== undefined ? props.dataS3?.username : Cookie.Get("username")}</p>
            <p className='ur'>{`#${props.dataS3.serial !== undefined ? props.dataS3.serial : Cookie.Get("id")}`}</p>
          </div>
        </div> : ""
      }
    </>
  )
}

export default Badge