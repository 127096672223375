import React from 'react';
import './index.scss';
import ExploreBtn from "@assets/images/explore-btn.png";
import { IDataBadgetStep3 } from '@interfaces/badge';
import Badge from '@components/reuseable/badge';
import { Spin } from 'antd';
import { useNavigate } from 'react-router-dom';

interface IStepThird {
  data: any,
}

const StepThird = (props: IStepThird) => {
  const navigate = useNavigate();
  return (
    <div className="explore">
      <div className="explore-title">
        <h2 className="ur">school id completed!</h2>
      </div>
      <div className="explore-badge">
        <Badge step={3} dataS3={props.data} dataS2="" setAvatar="" setColor="" setShape=""  />
      </div>
      <div className="explore-points">
        <p className="tb">You’ve gained <span>+100</span> points!</p>
      </div>
      <div className="explore-warn">
        <p className='tb'>A welcome email is sent to your parent's email.<br></br>Please save it in case you lose access to your account.</p>
      </div>
      <div className="explore-btn" onClick={() => navigate("/")}>
        <img src={ExploreBtn} alt="" />
      </div>
    </div>
  )
}

export default StepThird