import LockerDetail from '@components/partials/Lockers/DetailLocker';
import ListLockers from '@components/partials/Lockers/ListLockers';
import LoginRemind from '@components/reuseable/login_remind';
import Points from '@components/reuseable/score';
import { ISaveScore } from '@interfaces/score';
import { ScoreService } from '@services/score';
import { Cookie } from '@utils/cookie';
import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router';
import './index.scss';

const Lockers = () => {
    const [isShowDetail, setIsShowDetail] = useState(false);
    const [choosenLocker,  setChoosenLocker] = useState<string>();
    const [isOpenPoints, setIsOpenPoints] = useState(false);

    const [isShowLoginRemind, setIsShowLoginRemind] = useState(false);

    const navigate = useNavigate();
    const handleClickLocker = (data: string) => {
        setIsShowDetail(true);
        setChoosenLocker(data);
    }
    useEffect(() => {
        // if (!Cookie.Get("user_token")) {
        //     navigate("/")
        // }
        if (Cookie.Get("temp_locker")) {
            SaveScrores(Cookie.Get("temp_locker"));
        }
    }, []);
    const SaveScrores = async (id: any) => {
        const data: ISaveScore = {
            score: 50,
            activity: "VIEW_PROFILE",
            target: id
        }
        try {
            const res = await ScoreService.SaveScore(data);
            if (res) {
                setIsOpenPoints(true);
            }
        }
        catch(err) {
            console.log(err)
        }
        finally {
            Cookie.Remove("temp_locker");
        }
    }
    const handleCloseDetail = (data: string) => {
        setIsShowDetail(false); 
        if (Cookie.Get("user_token")) {
            SaveScrores(data);
        } else {
            Cookie.Set("temp_locker", data);
            setIsShowLoginRemind(true);
        }
    }
  return (
    <div className="lockers">
        <h2 className='ui'>Choose a doll to learn more about them</h2>
        <h2 className='ui mob'>Choose a doll to<br></br>learn more about them</h2>
        <div className="lockers-container">
            {
                !isShowDetail ? <ListLockers clicked={handleClickLocker} /> : <LockerDetail choosen={choosenLocker} close={handleCloseDetail} />
            }     
        </div>
        <Points isShowing={isOpenPoints} close={() => setIsOpenPoints(false)} point="50" />
        <LoginRemind 
            isShowing={isShowLoginRemind} 
            close={() => {
                Cookie.Remove("temp_locker");
                setIsShowLoginRemind(false);
            }}
        />
    </div>
  )
}

export default Lockers