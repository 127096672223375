import React, { useEffect, useState } from 'react'
import './index.scss';
import Game1 from '@assets/images/game-1.jpg';
import Game2 from '@assets/images/game-2.jpg';
import Game3 from '@assets/images/game-3.jpg';

interface ILaboratoryMinigame {
  game: string
}

const LaboratoryMinigame = (props: ILaboratoryMinigame) => {
  return (
    <div className="labor-game-frame">
      <div className="labor-game-frame-container">
        {/* <img src={props.game == 'quiz' ? Game1 : props.game == 'trivia' ? Game2 : Game3} alt="" /> */}
        { props.game && <iframe id={props.game} src={`${props.game}/index.html`}></iframe> }
      </div>
    </div>
  )
}

export default LaboratoryMinigame