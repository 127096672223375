import ProfileBox from '@components/partials/Profile/ProfileBox';
import ProfileFlag from '@components/partials/Profile/ProfileFlag';
import EditBadge from '@components/partials/Profile/EditBadge';
import { IProfile } from '@interfaces/auth';
import { AuthService } from '@services/auth';
import { Col, Row, Spin } from 'antd'
import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router';
import './index.scss';
import { IBadge } from '@interfaces/badge';
import { TrackingUtils } from '@utils/gtag';
import { trackVars } from '@utils/trackingVariables';

const Profile = () => {
    const navigate = useNavigate();
    const [isLoadingProfile, setIsLoadingProfile] = useState<boolean>(false);
    const [isLoadingEditBadge, setIsLoadingEditBadge] = useState<boolean>(false);
    const [dataProfile, setDataProfile] = useState<any>();
    const [isEditBadge, setIsEditBadge] = useState(false);
    const getProfile = async () => {
        setIsLoadingProfile(true);
        try {
            const res = await AuthService.GetProfile();
            if (res) {
                setDataProfile(res.data.data);
                if (res.data.data.badge == null) {
                    navigate("/create-account");
                }
            }
        }
        catch (err) {
            console.log(err);
            navigate("/login");
        }
        finally {
            setIsLoadingProfile(false);
        }
    }
    const submitEditBadge = async (data: IBadge) => {
        setIsLoadingEditBadge(true);
        try {
            const res = await AuthService.UpdateBadge(data);
            if (res) {
                setIsEditBadge(false);
            }
        }
        catch (err) {
            console.log(err)    
        }
        finally {
            setIsLoadingEditBadge(false);
        }
    }
    useEffect(() => {
        getProfile();
    }, [isEditBadge])
  return (
    <div className="profile">
        { isEditBadge && <div className="profile-edit-title">
            <h2 className='ui'>edit your school badge</h2>
        </div> }
        <div className="profile-container">
            <Row style={{height: "100%"}}>
                {
                    isEditBadge && dataProfile ? <EditBadge submit={submitEditBadge} loading={isLoadingEditBadge} data={dataProfile} /> :
                    <>
                        <Col xs={24} lg={8} xl={6} style={{height: "100%"}}>
                        {
                            !isLoadingProfile && dataProfile ? 
                            <ProfileFlag 
                                click={() => {
                                    TrackingUtils.SendGA(trackVars.eventName, trackVars.category ,trackVars.profile.lable, `${trackVars.profile.action}_EditSchoolBadge`)
                                    setIsEditBadge(true);
                                }} 
                                data={dataProfile}
                            /> : 
                            <Spin style={{position: "absolute", top: "50%", left:"50%", transform: "translate(-50%, -50%)"}} />
                        }
                        </Col>
                        <Col xs={24} lg={16} xl={18} style={{height: "100%"}}>
                            {
                                !isLoadingProfile && dataProfile ? <ProfileBox data={dataProfile} /> : <Spin style={{position: "absolute", top: "50%", left:"50%", transform: "translate(-50%, -50%)"}} /> 
                            }
                        </Col>
                    </>
                }
            </Row>
        </div>
    </div>
  )
}

export default Profile