import React, { useEffect, useState } from 'react'
import './index.scss';
import StepOne from '@components/partials/CreateAccount/FirstStep';
import StepSecond from '@components/partials/CreateAccount/SecondStep';
import StepThird from '@components/partials/CreateAccount/ThirdStep';
import Step1 from "../../assets/images/step-one.png";
import Step2 from "../../assets/images/step-two.png";
import StepForward from "../../assets/images/step-forward.png"; 
import { AuthService } from '@services/auth';
import { IBadge, IDataBadgetStep2, IDataBadgetStep3 } from '@interfaces/badge';
import { Cookie } from '@utils/cookie';
import { useOutletContext } from 'react-router';
import { TrackingUtils } from '@utils/gtag';
import { trackVars } from '@utils/trackingVariables';

const CreateAccount = () => {
  const [step, setStep] = useState(1);
  const [dataForBadgeS2, setDataForBadgeS2] = useState<IDataBadgetStep2>();
  const [dataForBadgeS3, setDataForBadgeS3] = useState<IDataBadgetStep3>();
  const [isLoadingRegis, setIsLoadingRegis] = useState(false);
  const [isLoadingBadge, setIsLoadingBadge] = useState(false);
  const {setIsLogined} = useOutletContext<any>();
  const onSubmitOne = async (data: any) => {
    TrackingUtils.SendGA(trackVars.eventName, trackVars.category, trackVars.studentID.lable, `${trackVars.studentID.action}_Submit`)
    setIsLoadingRegis(true);
    const data_regis = {
      username: data.username,
      password: data.password,
      email: data.mail,
      serial: parseFloat(data.serial)
    };
    const data_login = {
      username: data.username,
      password: data.password,
      serial: parseFloat(data.serial)
    }
    setDataForBadgeS2(data_login);
    try {
      const res = await AuthService.Register(data_regis);
      if (res) {
        setStep(2);
        const res_login = await AuthService.Login(data_login);
        if (res_login) {
          AuthService.SaveToken(res_login.data.data);
        }
      }
    }
    catch (err) {
      console.log(err)
    }
    finally {
      setIsLoadingRegis(false);
    }
  }

  const onSubmitSecond = async (e: any) => {
    TrackingUtils.SendGA(trackVars.eventName, trackVars.category, trackVars.studentID.lable, `${trackVars.studentID.action}_Registration`)
    setIsLoadingBadge(true);
    const data = {
      ...dataForBadgeS2!,
      shape: e.shape,
      avatar: e.avatar,
      color: e.color,
    };
    const data_badge: IBadge = {
      frame: e.shape == 'square' ? 'rect' : e.shape == 'border' ? 'round' : 'circle',
      background: e.color == 'purple' ? '/badge/1.png' 
        : e.color == 'pink' ? '/badge/2.png' 
        : e.color == 'yellow' ? '/badge/3.png' 
        : e.color == 'green' ? '/badge/4.png'
        : e.color == 'blue' ? '/badge/5.png' : '',
      image: e.avatar == 'one' ? '/avatar/1.png'
      : e.avatar == 'two' ? '/avatar/2.png' 
      : e.avatar == 'three' ? '/avatar/3.png' 
      : e.avatar == 'four' ? '/avatar/4.png'
      : e.avatar == 'five' ? '/avatar/5.png' : '',
    }
    try {
      const res = await AuthService.UpdateBadge(data_badge);
      if (res) {
        setIsLogined(true);
        // Cookie.Set("username", dataForBadgeS2?.username!);
        // Cookie.Set("id", dataForBadgeS2?.serial);
        setDataForBadgeS3(data);
        setStep(3);
      }
    }
    catch(err) {
      console.log(err)
    }
    finally {
      setIsLoadingBadge(false);
    }
  }

  useEffect(() => {
    if (Cookie.Get("user_token")) {
      setStep(2);
    }
  }, [])

  return (
    <div className="create">
      <h2 className="ui">create your school id</h2>
      <div className="create-box">
        <div className={`create-box-step ${step == 3 ? "none" : ""}`}>
          <img src={Step1} alt="Step-One" className={`create-box-step-item one ${step == 1 ? "active" : ""}`} />
          <img src={StepForward} className="create-box-step-forward"></img>
          <img src={Step2} alt="Step-Two" className={`create-box-step-item two ${step == 2 ? "active" : ""}`} />
        </div>
        {
          step == 1 ? <StepOne submit={onSubmitOne} loading={isLoadingRegis} /> : step == 2 ? <StepSecond submit={onSubmitSecond} data={dataForBadgeS2} loading={isLoadingBadge} /> : <StepThird data={dataForBadgeS3} />
        }
      </div>
    </div>
  )
}

export default CreateAccount