import React, { useEffect, useRef, useState } from 'react';
import './index.scss'
import Step1BtnText from "@assets/images/step-1-btn-text.png";
import DownIcon from "@assets/images/dropdown-icon.png";

import { Input, Select, Spin } from 'antd';

import OtpInput from 'react-otp-input';
import { AuthService } from '@services/auth';
import { usernames } from '@mocks/users';
import { TrackingUtils } from '@utils/gtag';
import { trackVars } from '@utils/trackingVariables';

interface IStepOne {
  submit: any,
  loading: boolean
}

const StepOne = (props: IStepOne) => {
  const [username, setUsername] = useState<string>();
  const [password, setPassword] = useState<string>();
  const [passwordConfirm, setPasswordConfirm] = useState<string>();
  const [serial, setSerial] = useState<string>("0001");
  const mailRef = useRef<any>();
  const [onChangeMail, setOnChangeMail] = useState<any>();
  const [mail, setMail] = useState<string>();
  const [errorMail, setErrorMail] = useState<boolean>(false);
  const [errorPwd, setErrorPwd] = useState<boolean>(false);
  const [disableBtn, setDisableBtn] = useState(true);

  const onChange = async (value: string) => {
    TrackingUtils.SendGA(trackVars.eventName, trackVars.category, trackVars.studentID.lable, `${trackVars.studentID.action}_${value}`);
    setUsername(value);
    const data = {
      username: value
    }
    try {
      const res = await AuthService.GetSerial(data);
      if (res) {
        setSerial(res.data.data.serial.toString());
      }
    }
    catch (err) {
      console.log(err);
    }
    finally {

    }
  };

  const handleChangeOTP = (data: any) => {
    setPassword(data);
    if (data.length == 4) {
      if (passwordConfirm && new String(passwordConfirm).valueOf() !== new String(data).valueOf()) {
        setErrorPwd(true);
      } else {
        setErrorPwd(false);
      }
    }
  }

  const handleChangeOTPConfirm = (data: any) => {
    setPasswordConfirm(data);
    if (data.length == 4) {
      if (new String(password).valueOf() !== new String(data).valueOf()) {
        setErrorPwd(true);
      } else {
        setErrorPwd(false);
      }
    }
  }

  useEffect(() => {
    let delayTime: any;
    if (!mailRef.current) {
      mailRef.current = true;
    } else {
      delayTime = setTimeout((() => {
        if (onChangeMail && !/^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i.test(onChangeMail)) {
          setErrorMail(true)
        } else {
          setErrorMail(false);
        }
        setMail(onChangeMail);
      }), 100)
    }
    return () => clearTimeout(delayTime);
  }, [onChangeMail]);

  useEffect(() => {
    if (parseInt(`${password?.length}`) == 4 && parseInt(`${passwordConfirm?.length}`) == 4 && new String(password).valueOf() == new String(passwordConfirm).valueOf() && username && mail && !errorMail) {
      setDisableBtn(false);
    } else {
      setDisableBtn(true);
    }
  }, [username, mail, password, passwordConfirm])

  return (
    <>
      <div className="form">
        <div className="form-input select">
          <p className='form-input-label ur'>Username</p>
          <div className="group">
            <Select
              suffixIcon={<img src={DownIcon}></img>}
              className="form-input-select"
              placeholder="Pick a username"
              optionFilterProp="children"
              onChange={onChange}
              options={usernames}
              listHeight={180}
              dropdownAlign={{ offset: [0, 0] }}
              dropdownStyle={{zIndex: 11}}
            />
            <p className='form-input-id tb'><span>#</span>{serial}</p>
          </div>
        </div>
        <div className="form-input code">
          <p className='form-input-label ur'>passcode</p>
          <form onKeyPress={(e) => {
              if (!disableBtn && e.key === 'Enter') {
                props.submit({
                  username: username,
                  password: password,
                  passwordConfirm: passwordConfirm,
                  mail: mail,
                  serial: serial
                })
              }
            }}>
            <OtpInput
              value={password}
              onChange={handleChangeOTP}
              numInputs={4}
              isInputNum={true}
              shouldAutoFocus={false}
              className="form-input-otp"
              containerStyle={"form-otp_wrap"}
            />
          </form>
        </div>
        <div className="form-input code">
          <p className='form-input-label ur'>confirm passcode</p>
          <form onKeyPress={(e) => {
              if (!disableBtn && e.key === 'Enter') {
                props.submit({
                  username: username,
                  password: password,
                  passwordConfirm: passwordConfirm,
                  mail: mail,
                  serial: serial
                })
              }
            }}>
            <OtpInput
              value={passwordConfirm}
              onChange={handleChangeOTPConfirm}
              numInputs={4}
              isInputNum={true}
              shouldAutoFocus={false}
              className={`form-input-otp ${errorPwd ? "error" : ""}`}
              containerStyle={"form-otp_wrap"}
            />
          </form>
        </div>
        <div className="form-input mail">
          <p className='form-input-label ur'>parent’s email</p>
          <Input 
            className={`${errorMail ? "error" : ""}`} 
            onChange={(e: any) => setOnChangeMail(e.target.value)} 
            onKeyPress={(e) => {
              if (e.key === 'Enter' && !disableBtn) {
                props.submit({
                  username: username,
                  password: password,
                  passwordConfirm: passwordConfirm,
                  mail: mail,
                  serial: serial
                })
              }
            }} 
          placeholder='Enter your parent’s email' />
        </div>
        <div className="form-warn">
          <p className='tb'>The winners will be contacted via the email given here. Please make sure the email is correct.</p>
        </div>
        <button disabled={disableBtn} className="form-confirm" onClick={() => props.submit({
          username: username,
          password: password,
          passwordConfirm: passwordConfirm,
          mail: mail,
          serial: serial
        })}>
          <div className="form-confirm-btn">
            {
              props.loading ? <Spin /> : <img src={Step1BtnText} data-user={username} data-pwd={password} data-pwdc={passwordConfirm} data-mail={mail} data-serial={serial} />
            }
          </div>
          {/* <img className={`${disableBtn ? "disable" : ""}`} src={ConfirmBtn} data-user={username} data-pwd={password} data-pwdc={passwordConfirm} data-mail={mail} data-serial={serial} alt=""/> */}
        </button>
      </div>
    </>
  )
}

export default StepOne