import { SCORE_ENDPOINT } from "@enums/score";
import { ISaveScore } from "@interfaces/score";
import { Axios } from "@utils/axios";
import { Cookie } from "@utils/cookie";
import { AxiosPromise } from "axios";

class ScoreService {
    SaveScore(data: ISaveScore): AxiosPromise {
        return Axios.post(SCORE_ENDPOINT.SAVE_SCORE, data, { headers: {Authorization: `Bearer ${Cookie.Get("user_token")}` } });
    }
}

const scoreService = new ScoreService();
export { scoreService as ScoreService }