import React, { useState } from 'react'
import './index.scss';
import { Spin } from 'antd';
import Badge from '@components/reuseable/badge';
import Step2BtnText from '@assets/images/step-2-btn-text.png';
import { SubmitBtn, Shape_1, Shape_2, Shape_3, Avatar_1, Avatar_2, Avatar_3, Avatar_4, Avatar_5, Color_1, Color_2, Color_3, Color_4, Color_5 } from '@components/reuseable/image';

interface IStepTwo {
  submit: any,
  loading: boolean,
  data: any
}


const StepSecond = (props: IStepTwo) => {
  const [shape, setShape] = useState<string>("square");
  const [avatar, setAvatar] = useState<string>("one");
  const [color, setColor] = useState<string>("purple");
  const onClickShape = (e: any) => {
    let data = e.target.getAttribute("data-shape");
    setShape(data);
  }
  const onClickAvatar = (e: any) => {
    let data = e.target.getAttribute("data-avatar");
    setAvatar(data);
  }
  const onClickColor = (e: any) => {
    let data = e.target.getAttribute("data-color");
    setColor(data);
  }
  return (
    <>
      <div className="group">
      <div className="group-split group-left">
        {/* Choose Shape */}
        <div className="group-left-item">
          <div className="group-left-item-title">
            <p className='tb'>Choose a shape:</p>
          </div>
          <div className="group-left-item-options">
            <img className={`${shape == "square" ? "active" : ""}`} onClick={onClickShape} data-shape="square" src={Shape_1} alt="" />
            <img className={`border ${shape == "border" ? "active" : ""}`} onClick={onClickShape} data-shape="border" src={Shape_2} alt="" />
            <img className={`circle ${shape == "circle" ? "active" : ""}`} onClick={onClickShape} data-shape="circle" src={Shape_3} alt="" />
          </div>
        </div>
        {/* Choose Avatar */}
        <div className="group-left-item">
          <div className="group-left-item-title">
            <p className='tb'>Choose an image:</p>
          </div>
          <div className="group-left-item-options border">
            <img className={`${avatar == "one" ? "active" : ""}`} onClick={onClickAvatar} data-avatar="one" src={Avatar_1} alt="" />
            <img className={`${avatar == "two" ? "active" : ""}`} onClick={onClickAvatar} data-avatar="two" src={Avatar_2} alt="" />
            <img className={`${avatar == "three" ? "active" : ""}`} onClick={onClickAvatar} data-avatar="three" src={Avatar_3} alt="" />
            <img className={`${avatar == "four" ? "active" : ""}`} onClick={onClickAvatar} data-avatar="four" src={Avatar_4} alt="" />
            <img className={`${avatar == "five" ? "active" : ""}`} onClick={onClickAvatar} data-avatar="five" src={Avatar_5} alt="" />
          </div>
        </div>
        {/* Choose Badge */}
        <div className="group-left-item">
          <div className="group-left-item-title">
            <p className='tb'>Choose a colour :</p>
          </div>
          <div className="group-left-item-options circle">
            <img className={`${color == "purple" ? "active" : ""}`} onClick={onClickColor} data-color="purple" src={Color_1} alt="" />
            <img className={`${color == "pink" ? "active" : ""}`} onClick={onClickColor} data-color="pink" src={Color_2} alt="" />
            <img className={`${color == "yellow" ? "active" : ""}`} onClick={onClickColor} data-color="yellow" src={Color_3} alt="" />
            <img className={`${color == "green" ? "active" : ""}`} onClick={onClickColor} data-color="green" src={Color_4} alt="" />
            <img className={`${color == "blue" ? "active" : ""}`} onClick={onClickColor} data-color="blue" src={Color_5} alt="" />
          </div>
        </div>
      </div>
      <div className="group-split group-right">
        <Badge setShape={shape} setAvatar={avatar} setColor={color} dataS2={props.data} dataS3="" step={2} />
      </div>
      </div>
      <button className="btn" onClick={() => props.submit({
        shape: shape,
        color: color,
        avatar: avatar
      })}>
        <div className="btn-group">
            {
              props.loading ? <Spin /> : <img src={Step2BtnText} data-shape={shape} data-avatar={avatar} data-color={color} />
            }
          </div>
        {/* <img data-shape={shape} data-avatar={avatar} data-color={color} src={SubmitBtn} alt="" /> */}
      </button>
    </>
  )
}

export default StepSecond