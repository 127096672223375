import HomePage from '@screens/homepage';
import Laboratory from '@screens/laboratory';
import Lockers from '@screens/lockers';
import Profile from '@screens/profile';
import Theatre from '@screens/theatre';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import './App.scss'
import AppLayout from './layouts';
import CreateAccount from './screens/createAccount';
import Login from './screens/login';

const routesConfig = [
  {
    element: <AppLayout />,
    children: [
      {
        path: "/",
        element: <HomePage />,
      },
      {
        path: "/login",
        element: <Login />,
      },
      {
        path: "/create-account",
        element: <CreateAccount />,
      },
      {
        path: "/laboratory",
        element: <Laboratory />
      },
      {
        path: "/theatre",
        element: <Theatre />
      },
      {
        path: "/profile",
        element: <Profile />
      },
      {
        path: "/lockers",
        element: <Lockers />
      }
    ],
  },
];

const router = createBrowserRouter(routesConfig);

function App() {
  return <RouterProvider router={router} />;
}

export default App;
