import { HomeBtnLabo, HomeBtnLocker, HomeBtnTheatre, HomeDolls } from '@components/reuseable/image';
import { Cookie } from '@utils/cookie';
import { useNavigate } from 'react-router';
import './index.scss';
import { TrackingUtils } from '@utils/gtag';
import { trackVars } from '@utils/trackingVariables';

interface IHomeDollProps {
    visitPage: any
}

function capitalize(s: string)
{
    return s[0].toUpperCase() + s.slice(1);
}

const HomeDoll = (props: IHomeDollProps) => {
    const navigate = useNavigate();
    const handleClickLabo = (data: string) => {
        navigate(`/${data}`);
        // if (Cookie.Get("user_token")) {
        // } else {
        //     props.visitPage();
        // }
    }
    const onTypeClick = (data: string) => {
        handleClickLabo(data);
        TrackingUtils.SendGA(trackVars.eventName, trackVars.category, trackVars.homepage.lable, `${trackVars.homepage.action}_Visit${capitalize(data)}`)
    }
    return (
        <>
            <div className="home-dolls">
                <img onClick={() => onTypeClick("laboratory")} className="home-dolls-btn labo" src={HomeBtnLabo} alt="" />
                <img onClick={() => onTypeClick("theatre")} className="home-dolls-btn theat" src={HomeBtnTheatre} alt="" />
                <img onClick={() => onTypeClick("lockers")} className="home-dolls-btn locker" src={HomeBtnLocker} alt="" />
                <img className="home-dolls-group" src={HomeDolls} alt="" />
            </div>
            <div className="home-mbtn">
                <img onClick={() => onTypeClick("lockers")} className="home-mbtn-btn locker" src={HomeBtnLocker} alt="" />
                <div className="home-mbtn-row">
                    <img onClick={() => onTypeClick("laboratory")} className="home-mbtn-btn labo" src={HomeBtnLabo} alt="" />
                    <img onClick={() => onTypeClick("theatre")} className="home-mbtn-btn theat" src={HomeBtnTheatre} alt="" />
                </div>
            </div>
        </>
    )
}

export default HomeDoll