export function itemInViewPort(item: Element, distance = 0) {
    let rect = item.getBoundingClientRect();
    return rect.top <= (window.innerHeight - distance || document.documentElement.clientHeight - distance);
}

export function showInView() {
    document.querySelectorAll('[data-showinview="false"]').forEach((item: Element) => {
        if (itemInViewPort(item)) {
          item.setAttribute("data-showInView", "true");
        }
    });
}