
import React, { useState } from 'react'
import './index.scss';
import Logo from "../assets/images/main-logo.png"
import BackBtn from "../assets/images/back-btn.png";
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import { Cookie } from '@utils/cookie';
import { TrackingUtils } from '@utils/gtag';
import { trackVars } from '@utils/trackingVariables';

const AppLayout = () => {
  const navigate = useNavigate();
  const [isLogined, setIsLogined] = useState(false);
  const location = useLocation();
  let user = Cookie.Get("username");
  let id = Cookie.Get("id");
  const handleLogin = () => {
    navigate("/login");
    TrackingUtils.SendGA(trackVars.eventName, trackVars.category, trackVars.homepage.lable, `${trackVars.homepage.action}_Login`);
  }
  return (
    <div className="layout">
        <div className="layout-head">
          <img onClick={() => location.pathname == "/" ? navigate(0) : navigate("/")} src={Logo} alt="Logo" />
          {
            isLogined || (user && id) ? <p onClick={() => navigate("/profile")} className="layout-head-title welcome">Welcome, Student<br></br><span className='ur'>{user}#{id}</span></p> 
            : <p className="layout-head-title login">Already have a Student ID? <br className="br-mob" /> <a onClick={handleLogin}>Log In</a></p>
          }
          
        </div>
        <div className="layout-body">
          {
            location.pathname !== "/" ? (
              <a className="layout-body-back" onClick={() => navigate("/")}>
                <img src={BackBtn} alt="BackBtn" />
              </a>
            ) : ""
          }
          <Outlet context={{setIsLogined}} />
        </div>
        <div className="layout-bottom">
          <div className="layout-bottom-text">
            <p className='utm'>©2023 Mattel</p>
            <div className="layout-bottom-text-group">
              <a href="pdf/Mattel - Monster High - Microsite Terms of Use.pdf" download className='utm'>Terms of Use</a>
              {/* <a href="pdf/Mattel - Monster High - Competition Terms & Conditions.pdf" download className='utm'>Competition Terms and Conditions</a> */}
            </div>
          </div>
        </div>
    </div>
  )
}

export default AppLayout 
