import React from 'react'
import { Cookie } from '@utils/cookie';
import './index.scss'

interface IBadgeAPI {
  shape: string,
  avatar: string,
  color: string
}

const BadgeAPI = (props: IBadgeAPI) => {
  return (
    <div className="badgeAPI" style={{background: `url(${require(`../../../assets/images${props.color}`)})`}}>
        <div className="badgeAPI-avatar" style={{background: `url(${require(`../../../assets/images/${props.shape}/${props.avatar.substring(props.avatar.length - 5)}`)})`}}></div>
        <div className="badgeAPI-info">
            <p className='ur'>{Cookie.Get("username")}</p>
            <p className='ur'>#{Cookie.Get("id")}</p>
        </div>
    </div>
  )
}

export default BadgeAPI