import React from 'react'
import './index.scss';
import CharacterLabo from '@assets/images/labo-guide-character.png';
import NameLabo from '@assets/images/labo-guide-name.png';
import CharacterTheatre from '@assets/images/theatre-guide-character.png';
import NameTheatre from '@assets/images/theatre-guide-name.png';
import { HomeGuideCharacter, HomeGuideName } from '../image';

interface IGuide {
    isShow: boolean,
    section: string,
    text: string,
    isHome: boolean
}

const Guide = (props: IGuide) => {
  return (
    <div className={`guide ${props.isShow ? "active" : "" }`} id="guide">
        <div className="guide-box">
            <div className={`guide-box-character ${props.section == "theatre" ? "th" : props.section == "homepage" ? "home" : "labo"}`}>
                <img src={props.section == "labor" ? CharacterLabo : props.section == "homepage" ? HomeGuideCharacter : CharacterTheatre} alt="" />
                <img src={props.section == "labor" ? NameLabo : props.section == "homepage" ? HomeGuideName : NameTheatre} alt="" />
            </div>
            <div className={`guide-box-text ${props.section == "theatre" ? "th" : props.section == "home" ? "home" : ""}`}>
                <p className='g-book'>{props.text}</p>
                <h4 className='ui'>TAP ANYWHERE TO CONTINUE</h4>
            </div>
        </div>
    </div>
  )
}

export default Guide