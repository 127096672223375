export const detailLockers = [
    {
        name: 'clawdeen',
        type: ["Clawsome", "Werewolf/Human"],
        have: ["Fur-liminator Brush", "Blue Moon Spookies"],
        pet: "Crescent™"
    },
    {
        name: 'frankie',
        type: ["Voltageous", "Frankenmonster"],
        have: ["iCoffin & Screechza"],
        pet: "Watzie™"
    },
    {
        name: 'draculaura',
        type: ["Fangtastic Vampire"],
        have: ["SPF 500 & cherry juice"],
        pet: "Count Fabulous™"
    },
    {
        name: 'cleo',
        type: ["Fab-boo-lous Royal Mummy"],
        have: ["Sar-coffee-gus", "& Pyramid backpack"],
        pet: "Tut™"
    },
    {
        name: 'lagoona',
        type: ["Splash-tastic", "Sea Monster"],
        have: ["Shellphone", "& Kelp Krisps"],
        pet: "Neptuna™"
    },
]