import { Col, Row } from 'antd'
import React, { useEffect, useState } from 'react'
import './index.scss'
import CloseBox from "@assets/images/locker-detail-close.png";
import { LockersCircleCleo, LockersCircleClawdeen, LockersCircleDraculaura, LockersCircleFrankie, LockersCircleLagoon } from '@components/reuseable/image';
import { detailLockers } from '@mocks/detailLocker';
import { ISaveScore } from '@interfaces/score';
import { ScoreService } from '@services/score';
import Points from '@components/reuseable/score';
import { TrackingUtils } from '@utils/gtag';
import { trackVars } from '@utils/trackingVariables';

interface ILockerDetail {
    choosen?: string,
    close: any
}

interface IDetailData {
    name: string,
    type: any,
    have: Array<string>,
    pet: string
}

const LockerDetail = (props: ILockerDetail) => {
    const [choosen, setChoosen] = useState(props.choosen);
    const [dataDetail, setDataDetail] = useState<IDetailData>();
    const [isOpenPoints, setIsOpenPoints] = useState(false);
    const handleClickItem = (e: any) => {
        let target = e.target.getAttribute('data-name');
        let trackName = target == "frankie" ? "FrankieStein" :
            target == "lagoona" ? "LagoonaBlue" : 
            target == "clawdeen" ? "ClawdeenWolf" : 
            target == "draculaura" ? "Draculaura" : 
            target == "cleo" ? "CleodeNile" : ""
        TrackingUtils.SendGA(trackVars.eventName, trackVars.category, trackVars.lockers.lable, `${trackVars.lockers.lable}_${trackName}`)
        SaveScrores(choosen)
        setTimeout(() => {
            setChoosen(target);
        }, 300)
    };
    const SaveScrores = async (id: any) => {
        const data: ISaveScore = {
            score: 50,
            activity: "VIEW_PROFILE",
            target: id
        }
        try {
            const res = await ScoreService.SaveScore(data);
            if (res) {
                setIsOpenPoints(true);
            }
        }
        catch(err) {
            console.log(err)
        }
        finally {

        }
    }
    useEffect(() => {
        detailLockers.map((detail: IDetailData) => {
            if (detail.name == choosen) {
                setDataDetail(detail);
            }
        });
    }, [choosen])
  return (
    <div className="lockers-detail">
        <Row style={{height: "100%"}}>
            <Col className="col-top" xs={24} lg={20} style={{height: "100%"}}>
                <div className="lockers-detail-combo">
                    <div className="lockers-detail-combo-character">
                        <img src={require(`../../../../assets/images/locker-detail/${choosen}.png`)} alt="" />
                    </div>
                    <div className="lockers-detail-combo-box">
                        <img className="title" src={require(`../../../../assets/images/locker-name/${choosen}.png`)} alt="" />
                        <div className="options">
                            <h4 className="g-m">monster type:</h4>
                            {
                                dataDetail?.type.map((el: string, index: number) => (
                                    <p key={index} className="g-book">{el}</p>
                                ))
                            }
                        </div>
                        <div className="options">
                            <h4 className="g-m">monster must haves:</h4>
                            {
                                dataDetail?.have.map((el: string, index: number) => (
                                    <p key={index} className="g-book">{el}</p>
                                ))
                            }
                        </div>
                        <div className="options">
                            <h4 className="g-m">monster pet:</h4>
                            <p className="g-book">{dataDetail?.pet}</p>
                        </div>
                        <img onClick={() => props.close(choosen)} src={CloseBox} alt="" className="close" />
                    </div>
                </div>
            </Col>
            <Col className="col-bot" xs={24} lg={4} style={{height: "100%"}}>
                <div className="lockers-detail-list">
                    <div className={`lockers-detail-list-item ${choosen === "clawdeen" && "active"}`} onClick={handleClickItem}>
                        <img data-name="clawdeen" src={LockersCircleClawdeen} alt="" />
                    </div>
                    <div className={`lockers-detail-list-item ${choosen === "frankie" && "active"}`} onClick={handleClickItem}>
                        <img data-name="frankie" src={LockersCircleFrankie} alt="" />
                    </div>
                    <div className={`lockers-detail-list-item ${choosen === "draculaura" && "active"}`} onClick={handleClickItem}>
                        <img data-name="draculaura" src={LockersCircleDraculaura} alt="" />
                    </div>
                    <div className={`lockers-detail-list-item ${choosen === "cleo" && "active"}`} onClick={handleClickItem}>
                        <img data-name="cleo" src={LockersCircleCleo} alt="" />
                    </div>
                    <div className={`lockers-detail-list-item ${choosen === "lagoona" && "active"}`} onClick={handleClickItem}>
                        <img data-name="lagoona" src={LockersCircleLagoon} alt="" />
                    </div>
                </div>
            </Col>
        </Row>
        <Points isShowing={isOpenPoints} close={() => setIsOpenPoints(false)} point="50" />
    </div>
  )
}

export default LockerDetail