import React from 'react'
import './index.scss'
import { LockersClawdeen, LockersCleo, LockersLagoon, LockersDraculaura, LockersFrankie } from '@components/reuseable/image';
import LockerIconLeft from "@assets/images/locker-icon-left.png";
import LockerIconRight from "@assets/images/locker-icon-right.png";
import { TrackingUtils } from '@utils/gtag';
import { trackVars } from '@utils/trackingVariables';

interface IListLockers {
    clicked: any
}

const ListLockers = (props: IListLockers) => {
    const handleClickLocker = (e: any) => {
        // TrackingUtils.SendGA("Swipe Right", "Character Gallery", "Engagement");
        let target = e.target.getAttribute('data-name');
        let all_items = document.querySelectorAll(".lockers-list-item");
        let trackName = target == "frankie" ? "FrankieStein" :
            target == "lagoona" ? "LagoonaBlue" : 
            target == "clawdeen" ? "ClawdeenWolf" : 
            target == "draculaura" ? "Draculaura" : 
            target == "cleo" ? "CleodeNile" : ""
        TrackingUtils.SendGA(trackVars.eventName, trackVars.category, trackVars.lockers.lable, `${trackVars.lockers.lable}_${trackName}`)
        all_items.forEach((el: any) => {
            if (!el.classList.contains(target)) {
                el.classList.add("fade");
            } else {
                setTimeout(() => {
                    el.classList.add("move");
                }, 300)
            }
        });
        setTimeout(() => {
            props.clicked(target);
        }, 800)
    }
  return (
    <div className="lockers-list">
        <img src={LockerIconLeft} alt="" className="lockers-list-icon left" />
        <div className="lockers-list-item frankie" onClick={handleClickLocker}>
            <img data-name="frankie" src={LockersFrankie} alt="" />
        </div>
        <div className="lockers-list-item lagoona" onClick={handleClickLocker}>
            <img data-name="lagoona" src={LockersLagoon} alt="" />
        </div>
        <div className="lockers-list-item clawdeen" onClick={handleClickLocker}>
            <img data-name="clawdeen" src={LockersClawdeen} alt="" />
        </div>
        <div className="lockers-list-item draculaura" onClick={handleClickLocker}>
            <img data-name="draculaura" src={LockersDraculaura} alt="" />
        </div>
        <div className="lockers-list-item cleo" onClick={handleClickLocker}>
            <img data-name="cleo" src={LockersCleo} alt="" />
        </div>
        <img src={LockerIconRight} alt="" className="lockers-list-icon right" />
    </div>
  )
}

export default ListLockers